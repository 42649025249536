import React from 'react';
import {colors} from '../../config';

export interface Props {
  touched: any;
  error: any;
  style?: any;
}

export const Error: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      {props.touched && props.error && (
        <div
          style={{
            alignSelf: 'flex-start',
            height: 'fit-content',
            width: 'fit-content',
            borderRadius: 8,
            borderColor: colors.danger,
            backgroundColor: colors.dangerLight,
            borderWidth: 2,
            paddingInline: 5,
            ...props.style,
          }}>
          <p
            style={{
              margin:3,
              fontSize: props.style?.fontSize ? props.style.fontSize : 15,
              color: props.style?.fontColor ? props.style.fontColor : 'white',
            }}>
            {props.error}
          </p>
        </div>
      )}
    </>
  );
};
