import { makeAutoObservable } from "mobx";
import { config } from "../config";
import {
  AdditionalsXReservationExt,
  Reservation,
  ResourcesXReservationExt,
} from "../interfaces";

class CartProvider {
  entityId?: number;
  entityName: string = "";

  reservation: Reservation = {};
  resources: ResourcesXReservationExt[] = [];
  additionals: AdditionalsXReservationExt[] = [];

  itemAmount: number = 0;
  totalPrice: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  canAddItem = (
    entityId: number,
    entityName: string,
    resource: ResourcesXReservationExt
  ) => {
    return new Promise((resolve, reject) => {
      if (this.entityId) {
        if (this.entityId != entityId) {
          reject("Entidad incorrecta");
          return;
        }
      } else {
        this.entityId = entityId;
        this.entityName = entityName;
      }

      if (this.reservation.fecha_reservacion) {
        if (
          this.reservation.fecha_reservacion != resource.fecha_recurso_reservado
        ) {
          reject(
            "Su "+config.getPlaceholders.reservation.singular.label+" actual pertenece a otra fecha (" +
              this.reservation.fecha_reservacion +
              "), complete "+config.getPlaceholders.reservation.singular.the+ " " + config.getPlaceholders.reservation.singular.label+ " y podrá generar "+config.getPlaceholders.reservation.singular.another+"."
          );
          return;
        }
      } else {
        this.reservation.fecha_reservacion = resource.fecha_recurso_reservado;
      }

      resolve({});
    });
  };

  addItem = (
    entityId: number,
    entityName: string,
    resource: ResourcesXReservationExt,
    additional?: AdditionalsXReservationExt
  ) => {
    return new Promise((resolve, reject) => {
      this.canAddItem(entityId, entityName, resource)
        .then(() => {
          this.resources.push(resource);
          this.itemAmount += resource.cantidad_reservada;
          this.totalPrice += resource.precio_total_reserva;
          if (additional) this.additionals.push(additional);
          resolve({});
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  removeItem = (resourceId: number) => {
    let filteredResource: ResourcesXReservationExt | undefined;
    this.resources = this.resources.filter((res) => {
      if (res.Recursos_id != resourceId) {
        return res;
      } else {
        filteredResource = res;
      }
    });
    if (filteredResource) {
      if (this.resources.length == 0) {
        this.cleanCart();
      } else {
        this.itemAmount -= filteredResource.cantidad_reservada;
        this.totalPrice -= filteredResource.precio_total_reserva;
      }
    }
  };

  cleanCart = () => {
    this.reservation = {};
    this.resources = [];
    this.additionals = [];
    this.itemAmount = 0;
    this.totalPrice = 0;
    this.entityId = undefined;
  };

  get getResources() {
    return this.resources;
  }

  get getAdditionals() {
    return this.additionals;
  }

  get getReservation() {
    return this.reservation;
  }

  get getEntityId() {
    return this.entityId;
  }

  set setEmail(value: string) {
    this.reservation.email_cliente = value;
  }

  get getItemAmount() {
    return this.itemAmount;
  }

  get getEntityName() {
    return this.entityName;
  }

  get getTotalPrice() {
    return this.totalPrice;
  }
}

export const cartProvider = new CartProvider();
