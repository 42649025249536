import React from "react";
import { Button } from "react-bootstrap";
export interface Props {
  setFile: (file: any) => void;
}

export const MediaUpload: React.FunctionComponent<Props> = (props: Props) => {
  const hiddenFileInput: any = React.useRef(null);
  const handleFileClick = () => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = (e: any) => {
    console.log(e.target.files);
    const fileUpload = e.target.files[0];
    if (!fileUpload.type.startsWith("image")) {
      //show error
    } else {
      props.setFile(fileUpload);
    }
  };

  return (
    <>
      <Button
        style={{
          width: "fit-content",
          height: "fit-content",
          margin: 10,
        }}
        onClick={handleFileClick}
      >
        +
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{
          display: "none",
        }}
      />
    </>
  );
};
