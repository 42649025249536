import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Icon, Form } from "..";
import { addBorders, addShadow, colors } from "../../config";
import { UtilsService } from "../../services";
export interface Props {
  fetchSchedule: () => Promise<any>;
  submitSchedule?: (schedule: {
    lunes: string;
    martes: string;
    miercoles: string;
    jueves: string;
    viernes: string;
    sabado: string;
    domingo: string;
  }) => Promise<void>;
  editable?: boolean;
  isLoading: boolean;
  style?:any;
}

export const Schedule: React.FunctionComponent<Props> = (props: Props) => {
  const [schedule, setSchedule] = useState<any>();
  const [scheduleId, setScheduleId] = useState<number>(-1);
  const [originalSchedule, setOriginalSchedule] = useState<any>({});
  const [scheduleError, setScheduleError] = useState<any>({
    lunes: [false, ""],
    martes: [false, ""],
    miercoles: [false, ""],
    jueves: [false, ""],
    viernes: [false, ""],
    sabado: [false, ""],
    domingo: [false, ""],
  });
  const [addSchedule, setAddSchedule] = useState<any>({
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,
  });
  const [touched, setTouched] = useState<boolean>(false);
  const [submitionAttempts, setSubmitionAttempts] = useState<number>(0);

  useEffect(() => {
    console.log("fetching schedule...")
    props
      .fetchSchedule()
      .then((sch) => {
        let schedule: any = {};
        let days = sch;
        setScheduleId(days.id);
        for (let day in days) {
          if (UtilsService.isDayES(day)) {
            let x: any;
            if (days[day]) {
              x = days[day].split(";");
              x = x.map((tr: any) => {
                return tr.split("-");
              });
            } else {
              x = [];
            }
            schedule[day] = x;
          }
        }
        setSchedule(schedule);
        setOriginalSchedule(schedule);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [submitionAttempts]);

  const removeTimeRange = (day: string, index: number) => {
    let sch = schedule;
    sch[day].splice(index, 1);
    setSchedule({ ...sch });
    setTouched(true);
  };

  const cleanScheduleError = (day: string) => {
    let schErr = scheduleError;
    schErr[day] = [false, ""];
    setScheduleError({ ...schErr });
  };

  const assignScheduleError = (day: string, message: string) => {
    let schErr = scheduleError;
    schErr[day] = [true, message];
    setScheduleError({ ...schErr });
  };

  const insertIntoSchedule = (
    index: number,
    day: string,
    from: string,
    to: string
  ) => {
    let sch = schedule;
    sch[day].splice(index, 0, [from, to]);
    setSchedule({ ...sch });
    setTouched(true);
  };

  const addTimeRange = (
    day: string,
    values: { Desde: string; Hasta: string }
  ) => {
    //If from > to -> Error!
    if (!UtilsService.isTimeBiggerOrEqual(values.Hasta, values.Desde)) {
      assignScheduleError(
        day,
        'El campo "Hasta" debe ser mayor al campo "Desde"'
      );
      return false;
    }

    let length = schedule[day].length;

    //If day has no time ranges, add it to the top
    if (length == 0) {
      cleanScheduleError(day);
      insertIntoSchedule(0, day, values.Desde, values.Hasta);
      return true;
    }

    let lastTime = schedule[day][length - 1][1];
    let firstTime = schedule[day][0][0];

    //If to is < first first range, safe to add schedule
    if (UtilsService.isTimeBiggerOrEqual(firstTime, values.Hasta)) {
      cleanScheduleError(day);
      insertIntoSchedule(0, day, values.Desde, values.Hasta);
      return true;
    }

    //If from is > last last range, safe to add schedule
    if (UtilsService.isTimeBiggerOrEqual(values.Desde, lastTime)) {
      cleanScheduleError(day);
      insertIntoSchedule(length, day, values.Desde, values.Hasta);
      return true;
    }

    //If none from above, check the correct position
    for (let i = 0; i < length - 1; i++) {
      if (
        UtilsService.isTimeBiggerOrEqual(values.Desde, schedule[day][i][1]) &&
        UtilsService.isTimeBiggerOrEqual(schedule[day][i + 1][0], values.Hasta)
      ) {
        cleanScheduleError(day);
        insertIntoSchedule(i + 1, day, values.Desde, values.Hasta);
        return true;
      }
    }

    //If no correct position, it lands here.
    assignScheduleError(
      day,
      "El rango seleccionado no es correcto de acuerdo a los ya existentes."
    );
    return false;
  };

  const submitSchedule = () => {
    let sch = schedule;
    let dayString: string;
    for (let day in sch) {
      dayString = "";
      sch[day].forEach((timeRange: string[], i: number) => {
        dayString += timeRange[0] + "-" + timeRange[1] + ";";
      });
      sch[day] = dayString ? dayString.slice(0, -1) : null;
    }
    sch.id = scheduleId;

    setSchedule(undefined);
    if (props.submitSchedule)
      props.submitSchedule(sch).finally(() => {
        setSubmitionAttempts(submitionAttempts + 1);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        ...props.style
      }}
    >
      {!props.isLoading && schedule && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: 10,
          }}
        >
          {Object.keys(schedule).map((day) => {
            return (
              <div
                key={day}
                style={{
                  //...addBorders,
                  //minHeight: 200,
                  maxWidth: 300,
                  minWidth: 250,
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  margin: 10,
                  padding: 10,
                  ...addShadow,
                  borderRadius: 10,
                  height: "fit-content",
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontVariantCaps: "all-small-caps",
                  }}
                >
                  {day} {schedule[day].length == 0 && "(cerrado)"}
                </p>
                {schedule[day].map((timeRange: string[], index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <p>
                        {timeRange[0]} - {timeRange[1]}
                      </p>
                      {props.editable == true && (
                        <Button
                          style={{
                            borderRadius: 30,
                            paddingBlock: 0,
                            paddingInline: 3,
                            marginInline: 5,
                            height: "fit-content",
                            width: "fit-content",
                          }}
                          variant="outline-danger"
                          onClick={() => {
                            removeTimeRange(day, index);
                          }}
                        >
                          <Icon name="trash" color={colors.danger} />
                        </Button>
                      )}
                    </div>
                  );
                })}

                {props.editable == true &&
                  (addSchedule[day] ? (
                    <Form
                      resetOnSubmit={true}
                      submit={{
                        call: (values) => {
                          if (addTimeRange(day, values)) {
                            let sch = addSchedule;
                            sch[day] = false;
                            setAddSchedule({ ...sch });
                            return true;
                          }
                          return false;
                        },
                        title: "Agregar rango horario",
                        error: scheduleError[day][0],
                        errorMessage: scheduleError[day][1],
                        errorStyle: { marginTop: 10 },
                      }}
                      inputs={[
                        {
                          label: "Desde",
                          placeHolder: "Desde",
                          constraints: [
                            {
                              type: "required",
                              message: "El tiempo por turno es obligatorio",
                            },
                          ],
                          extraValidations: [
                            {
                              title: "isTimeFormat",
                              message: "El formato debe ser HH:MM:SS",
                              validation: UtilsService.isTimeFormat,
                            },
                          ],
                          initialValue: "",
                          type: "text",
                          valueType: "text",
                        },
                        {
                          label: "Hasta",
                          placeHolder: "Hasta",
                          constraints: [
                            {
                              type: "required",
                              message: "El tiempo por turno es obligatorio",
                            },
                          ],
                          extraValidations: [
                            {
                              title: "isTimeFormat",
                              message: "El formato debe ser HH:MM:SS",
                              validation: UtilsService.isTimeFormat,
                            },
                          ],
                          initialValue: "",
                          type: "text",
                          valueType: "text",
                        },
                      ]}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        let sch = addSchedule;
                        sch[day] = true;
                        setAddSchedule({ ...sch });
                      }}
                      variant="success"
                    >
                      + rango horario
                    </Button>
                  ))}
              </div>
            );
          })}
        </div>
      )}
      {props.editable && (
        <Button
          disabled={!touched}
          variant="success"
          style={{
            marginTop: 20,
            marginInline: 20,
            maxWidth: 500,
          }}
          onClick={submitSchedule}
        >
          Actualizar Horario
        </Button>
      )}
    </div>
  );
};
