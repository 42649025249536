import { observer } from "mobx-react";
import React from "react";
import {
  Button,
  ButtonGroup,
  FloatingLabel,
  Form as FormBoot,
  InputGroup,
  Table,
} from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { Icon, Search } from "../../components";
import { config } from "../../config";
import { userProvider } from "../../providers";
import {
  ReservationService,
  StorageService,
  UtilsService,
} from "../../services";
import { ReservationItem } from "./ReservationItem";

export interface Props extends RouteComponentProps {}

interface State {
  searchUID?: string;
  reservations: any[];
}

class ReservationLobby extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchUID: UtilsService.parseURLSearchParams(props.location.search).uid,
      reservations: [],
    };
  }

  componentDidMount() {
    this.searchReservation();

    if (userProvider.getIsLoggued) {
      this.searchEntitysReservations();
    } else {
      let stringUIDs = "";
      let storedUIDs: any = StorageService.getReservationUIDs();
      storedUIDs = storedUIDs ? storedUIDs : [];
      storedUIDs.forEach((uid: any) => {
        stringUIDs += uid + "--";
      });
      stringUIDs = stringUIDs.slice(0, -2);

      ReservationService.getByUID(stringUIDs)
        .then((data = []) => {
          this.setState({
            reservations: data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  searchEntitysReservations = () => {
    let entityId: any = userProvider.getEntityId;
    ReservationService.getByEntity(entityId)
      .then((data = []) => {
        this.setState({
          reservations: data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  searchReservation = () => {
    if (this.state.searchUID) {
      ReservationService.getByUID(this.state.searchUID.toUpperCase())
        .then((data = []) => {
          this.setState({
            reservations: data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  clearSearch = () => {
    this.setState({
      searchUID: "",
    });
    this.searchEntitysReservations();
  };

  goToReservation = (uid: string) => {
    this.props.history.push("/reservation/" + uid);
  };

  render() {
    return (
      <div style={{ padding: 20 }}>
        <h1>Buscador de {config.getPlaceholders.reservation.plural.label}</h1>

        <Search
          placeHolder={
            "Código de " + config.getPlaceholders.reservation.singular.label
          }
          clearSearch={this.clearSearch}
          search={this.searchReservation}
          onType={(value: any) => {
            this.setState({
              searchUID: value,
            });
          }}
          searchValue={this.state.searchUID}
          style={{
            maxWidth: 500,
          }}
        />
        {this.state.reservations.length > 0 ? (
          <Table
            style={{
              width: "100%",
            }}
            striped={true}
            bordered={true}
            hover={true}
            responsive
          >
            <thead>
              <tr>
                <th>Código</th>
                <th>Email Cliente</th>
                <th>{config.getPlaceholders.entity.singular.label}</th>
                <th>Estado</th>
                <th>Fecha de Creación</th>
                <th>
                  Fecha de {config.getPlaceholders.reservation.singular.label}
                </th>
                <th>Precio</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {this.state.reservations.map((reservation: any) => {
                return (
                  <ReservationItem
                    key={reservation.id}
                    reservation={reservation}
                    selectReservation={this.goToReservation}
                  />
                );
              })}
            </tbody>
          </Table>
        ) : (
          <h5>No se encontraron reservas recientes...</h5>
        )}
      </div>
    );
  }
}

export default withRouter(observer(ReservationLobby));
