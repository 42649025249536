import React from "react";
import { Spinner } from "react-bootstrap";
import { Spinner as Spin } from "../";

export interface Props {
  custom?: boolean;
}

export const Loader: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        zIndex: 1000,
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.custom ? (
        <Spinner animation={"grow"} variant="info">
          <Spinner animation={"grow"} variant="info">
            <Spinner animation={"grow"} variant="light"></Spinner>
          </Spinner>
        </Spinner>
      ) : (
        <Spin />
      )}
    </div>
  );
};
