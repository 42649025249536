import { observer } from "mobx-react";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Map,
  ResourceCard,
  SchedulePreviewModal,
  Search,
  SeatSelectorModal,
} from "../../components";
import { addBorders, addShadow, colors, config } from "../../config";
import {
  AdditionalsXReservation,
  AdditionalsXReservationExt,
  ResourcesXReservation,
  ResourcesXReservationExt,
} from "../../interfaces";
import { cartProvider, globalProvider, userProvider } from "../../providers";
import { EntityService, UtilsService, ResourceService } from "../../services";
import { ResourcePreview } from "../Resource/ResourcePreview";

export interface Props extends RouteComponentProps {
  id?: number;
}
interface State {
  entity: any;
  resources: any[];
  showSeatSelectorModal: boolean;
  seatSelectorError?: number;
  selectedResource: any;
  resourceAction: (resource: any) => void;
  resourceActionTitle: string;
  searchResource?: string;
  showScheduleModal: boolean;
  loadingSchedule: boolean;
}

class EntityScreen extends React.Component<Props, State> {
  id: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {},
      resources: [],
      showSeatSelectorModal: false,
      selectedResource: undefined,
      seatSelectorError: undefined,
      resourceAction: () => {},
      resourceActionTitle: "",
      showScheduleModal: false,
      loadingSchedule: false,
    };

    if (this.props.id) {
      this.id = this.props.id;
      this.props.history.replace("/home");
    } else {
      this.id = this.props.location.pathname.split("/").pop();
    }
  }

  componentDidMount() {
    globalProvider.setIsLoading = true;

    if (userProvider.getIsLoggued) {
      this.setState({
        resourceAction: this.goToEditResource,
        resourceActionTitle: "Editar",
      });
    } else {
      this.setState({
        resourceAction: this.addToReservation,
        resourceActionTitle:
          "Agregar a mi " + config.getPlaceholders.reservation.singular.label,
      });
    }

    EntityService.get(this.id).then((data) => {
      this.setState({
        entity: data,
      });
    });

    ResourceService.getByEntity(this.id, !userProvider.getIsLoggued)
      .then((data) => {
        this.setState({
          resources: data,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        globalProvider.setIsLoading = false;
      });
  }

  fetchEntitySchedule = () => {
    return new Promise((resolve, reject) => {
      EntityService.getSchedule(this.state.entity.id)
        .then((data = []) => {
          if (data.length > 0) {
            let schedule = data[0];
            resolve(schedule);
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  goToEditResource = (resource: any) => {
    globalProvider.setParam({
      for: "EditResourceScreen",
      value: resource,
    });
    this.props.history.push("/resource/" + resource.id + "/edit");
  };

  goToResource = (resource: any) => {
    globalProvider.setParam({
      for: "ResourceScreen",
      value: resource,
    });

    this.props.history.push("/resource/" + resource.id);
  };

  addToReservation = (resource: any) => {
    if (
      cartProvider.getEntityId &&
      cartProvider.getEntityId != this.state.entity.id
    ) {
      globalProvider.showAlert({
        type: "error",
        title:
          "Estás en " +
          config.getPlaceholders.entity.singular.another +
          " " +
          config.getPlaceholders.entity.singular.label +
          "!",
        message:
          "Tu " +
          config.getPlaceholders.reservation.singular.label +
          " actual pertenece a " +
          config.getPlaceholders.entity.singular.another +
          config.getPlaceholders.entity.singularLabel +
          ", " +
          config.getPlaceholders.reservations.singular.the +
          " debés concretar antes de poder generar " +
          config.getPlaceholders.reservation.singular.a +
          " " +
          config.getPlaceholders.reservation.singular.label +
          " de " +
          config.getPlaceholders.entity.singular.another +
          " " +
          config.getPlaceholders.entity.singular.label,
      });
      console.error("Different entity on existing cart");
      return;
    }

    if (
      cartProvider.getResources.find((res) => res.Recursos_id == resource.id)
    ) {
      globalProvider.showAlert({
        type: "error",
        title: "Repetido!",
        message:
          config.getPlaceholders.resource.singular.this +
          " " +
          config.getPlaceholders.resource.singular.label +
          " ya se encuentra en tu " +
          config.getPlaceholders.reservation.singular.label +
          " actual.",
      });

      console.error("Resource already on existing cart");
      return;
    }

    this.setState({
      showSeatSelectorModal: true,
      selectedResource: resource,
    });
  };

  closeSeatSelectorModal = () => {
    this.setState({
      showSeatSelectorModal: false,
      selectedResource: undefined,
    });
  };

  addItemToCart = (
    res: ResourcesXReservationExt,
    additional?: AdditionalsXReservationExt
  ) => {
    cartProvider
      .addItem(this.state.entity.id, this.state.entity.nombre, res, additional)
      .then(() => {
        this.closeSeatSelectorModal();
        globalProvider.showAlert({
          message: "Item agregado a tu carrito!",
          type: "info",
          alertType: "popup",
        });
      })
      .catch((error) =>
        globalProvider.showAlert({
          title: "Error...",
          message: error,
          type: "error",
        })
      );
  };

  goToMoreInfo = () => {};
  searchResource = () => {};
  clearSearch = () => {};

  render() {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {!userProvider.getIsLoggued ? (
          <SeatSelectorModal
            resource={this.state.selectedResource}
            show={this.state.showSeatSelectorModal}
            close={this.closeSeatSelectorModal}
            selectItem={this.addItemToCart}
          />
        ) : (
          <Button
            style={{
              position: "absolute",
              bottom: 30,
              right: 30,
              borderRadius: 50,
              paddingBlock: 0,
              paddingInline: 20,
              zIndex: 300,
              fontSize: 30,
            }}
            variant="success"
            onClick={() => {
              this.props.history.push("/resource/0/new");
            }}
          >
            +
          </Button>
        )}

        <SchedulePreviewModal
          title={"Horarios de " + this.state.entity.nombre}
          show={this.state.showScheduleModal}
          close={() => {
            this.setState({
              showScheduleModal: false,
            });
          }}
          fetchSchedule={this.fetchEntitySchedule}
          isLoading={this.state.loadingSchedule}
          style={{}}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            <h1>{this.state.entity.nombre}</h1>
            <h5>{this.state.entity.informacion}</h5>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outline-primary"
              style={{
                borderRadius: 10,
                marginBottom: 5,
                fontSize: 12,
                maxHeight: 35,
              }}
              onClick={() => {
                this.setState({
                  showScheduleModal: true,
                });
              }}
            >
              Horarios
            </Button>
            <Button
              variant="outline-primary"
              style={{
                borderRadius: 10,
                fontSize: 12,
                maxHeight: 45,
              }}
              onClick={this.goToMoreInfo}
            >
              Más Info
            </Button>
          </div>
        </div>

        <Map
          style={{
            marginBottom: 10,
          }}
          height={"30%"}
          center={{
            lat: -31.418227,
            lng: -64.161342,
          }}
          zoom={11}
        />

        <h3>{config.getPlaceholders.resource.plural.extendedLabel}</h3>
        {this.state.resources.length > 0 ? (
          <>
            <Search
              search={this.searchResource}
              clearSearch={this.clearSearch}
              searchValue={this.state.searchResource}
              onType={(value: any) => {
                this.setState({
                  searchResource: value,
                });
              }}
              style={{
                maxWidth: 500,
              }}
              placeHolder={
                "Busca " +
                config.getPlaceholders.resource.plural.our +
                " " +
                config.getPlaceholders.resource.plural.label +
                "..."
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {this.state.resources.map((res) => {
                return (
                  <ResourcePreview
                    key={res.id}
                    resource={res}
                    action={this.state.resourceAction}
                    actionTitle={this.state.resourceActionTitle}
                    moreInfo={this.goToResource}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <h4>
            {config.getPlaceholders.entity.singular.this}{" "}
            {config.getPlaceholders.entity.singular.label} todavia no tiene{" "}
            {config.getPlaceholders.resource.plural.label} para contratar
          </h4>
        )}
      </div>
    );
  }
}

export default withRouter(observer(EntityScreen));
