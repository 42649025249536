import { observer } from "mobx-react";
import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { ReservationDetailsCard, Ticket } from "../../components";
import {
  addBorders,
  addShadow,
  colors,
  commonCard,
  commonCardM,
  commonCardSM,
  config,
  styleConstants,
} from "../../config";
import {
  AdditionalsXReservation,
  AdditionalsXReservationExt,
  Reservation,
  ReservationExt,
  ResourcesXReservation,
  ResourcesXReservationExt,
} from "../../interfaces";
import { globalProvider } from "../../providers";
import { ReservationService, UtilsService } from "../../services";
import { ReservationActions } from "./ReservationActions";

export interface Props extends RouteComponentProps {}

interface State {
  reservation?: ReservationExt;
  resources: ResourcesXReservationExt[];
  additionals: AdditionalsXReservationExt[];
  error: {
    reservation: boolean;
    resources: boolean;
    additionals: boolean;
  };
  entityName: string;
}

class ReservationScreen extends React.Component<Props, State> {
  reservationUID: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      reservation: globalProvider.getParam("ReservationScreen"),
      resources: [],
      additionals: [],
      error: {
        reservation: false,
        resources: false,
        additionals: false,
      },
      entityName: "...",
    };

    this.reservationUID = this.props.location.pathname.split("/").pop();
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    if (!this.state.reservation) {
      await ReservationService.getByUID(this.reservationUID)
        .then((data = []) => {
          if (data.length > 0) {
            let r: any = data[0];
            console.log("Reservation: ", r);
            this.setState({
              reservation: {
                Entidades_id: r.Entidades_id,
                email_cliente: r.email_cliente,
                estado_reservacion: r.estado_reservacion,
                fecha_creacion: new Date(r.fecha_creacion),
                fecha_reservacion: new Date(r.fecha_reservacion),
                id: r.id,
                precio_total_reserva: r.precio_total_reserva,
                uid: r.uid,
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            error: {
              ...error,
              reservation: true,
            },
          });
        });
    }
    ReservationService.getResourcesByUID(this.reservationUID)
      .then((data = []) => {
        let res: ResourcesXReservationExt[] = data.map((r: any) => {
          return {
            nombre: r.nombre,
            tiempo_turno: r.tiempo_turno,
            personas_habilitadas: r.personas_habilitadas,
            Recursos_id: r.Recursos_id,
            hora_inicio_recurso_reservado: r.hora_inicio_recurso_reservado,
            hora_final_recurso_reservado: r.hora_final_recurso_reservado,
            fecha_recurso_reservado: r.fecha_recurso_reservado,
            cantidad_reservada: r.cantidad_reservada,
            precio_total_reserva: r.precio_total_reserva,
          };
        });
        console.log("Resources: ", res);
        this.setState({
          resources: res,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          error: {
            ...error,
            resources: true,
          },
        });
      });

    ReservationService.getAdditionalsByUID(this.reservationUID)
      .then((data = []) => {
        let addi: AdditionalsXReservationExt[] = data.map((a: any) => {
          return {
            Adicionales_id: a.Adicionales_id,
            cantidad_reservada: a.cantidad_reservada,
            descripcion: a.descripcion,
            precio_total_reserva: a.precio_total_reserva,
            nombre: a.nombre,
          };
        });
        console.log("Additionals: ", addi);
        this.setState({
          additionals: addi,
        });
      })
      .catch((error) => {
        console.error("Adi Error: ", error);
        this.setState({
          error: {
            ...error,
            additionals: true,
          },
        });
      });

    let entityId: any = this.state.reservation?.Entidades_id;
    globalProvider
      .getEntityName(entityId)
      .then((name) => {
        this.setState({
          entityName: name,
        });
      })
      .catch((error) => {});
  };

  updateReservationState = (reservation: any, state: string) => {
    this.setState({
      reservation: { ...reservation, estado_reservacion: state },
    });
  };

  goToResource = (resourceId: any) => {
    this.props.history.push("/resource/" + resourceId);
  };

  render() {
    return (
      <div
        style={{
          //padding: 10,
          width: "100%",
          height: "100%",
        }}
      >
        {this.state.reservation && (
          <>
            <h1 style={{ margin: 10 }}>{config.getPlaceholders.reservation.singular.label} {this.state.reservation.uid}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    ...commonCardSM,
                  }}
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    Info de {config.getPlaceholders.reservation.singular.label}
                  </p>
                  <Ticket
                    items={[
                      {
                        variable: "Cliente",
                        value: this.state.reservation.email_cliente,
                      },
                      {
                        variable: "Estado",
                        value: this.state.reservation.estado_reservacion,
                      },
                      {
                        variable: "Fecha de Creación",
                        value: UtilsService.parseDate(
                          this.state.reservation.fecha_creacion
                        ),
                      },
                      {
                        variable: "Fecha de "+ config.getPlaceholders.reservation.singular.label,
                        value: UtilsService.parseDate(
                          this.state.reservation.fecha_reservacion
                        ),
                      },
                    ]}
                  />
                </div>

                <div
                  style={{
                    ...commonCardSM,
                  }}
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>Acciones</p>
                  <p style={{ fontWeight: "bold" }}>Actualizar Estado</p>
                  <ReservationActions
                    entityId={this.state.reservation.Entidades_id}
                    reservationId={this.state.reservation.id}
                    setReservationState={(state: any) => {
                      this.updateReservationState(
                        this.state.reservation,
                        state
                      );
                    }}
                    state={this.state.reservation.estado_reservacion}
                  />
                </div>
              </div>

              <ReservationDetailsCard
                emptyMessage={"Faltan datos en "+config.getPlaceholders.reservation.singular.the+" " + config.getPlaceholders.reservation.singular.label+"..."}
                entityName={this.state.entityName}
                reservation={{
                  email_cliente: this.state.reservation.email_cliente,
                  fecha_reservacion: UtilsService.parseDate(
                    this.state.reservation.fecha_creacion
                  ),
                }}
                resources={this.state.resources}
                totalPrice={this.state.reservation.precio_total_reserva}
                additionals={this.state.additionals}
                goToResource={this.goToResource}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(observer(ReservationScreen));
