import React from "react";
import {
  Button,
  ButtonGroup,
  InputGroup,
  Form as FormBoot,
} from "react-bootstrap";
import { Icon } from "..";
export interface Props {
  onType: (value: any) => void;
  searchValue: any;
  clearSearch: () => void;
  search: () => void;
  style?: any;
  placeHolder?:string
}

export const Search: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        ...props.style,
      }}
    >
      <InputGroup className="mb-3">
        <FormBoot.Control
          type={"text"}
          placeholder={props.placeHolder}
          style={{}}
          onChange={(e) => {
            props.onType(e.target.value);
          }}
          value={props.searchValue}
        />
        <ButtonGroup>
          <Button
            style={{
              borderWidth: 0,
            }}
            disabled={!props.searchValue}
            variant="outline-secondary"
            onClick={props.clearSearch}
          >
            <Icon name={"clear"} />
          </Button>
          <Button onClick={props.search}>
            <Icon name={"search"} />
          </Button>
        </ButtonGroup>
      </InputGroup>
    </div>
  );
};
