import { Button, ButtonGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { colors } from "../../config";
import { globalProvider, userProvider } from "../../providers";
import { ReservationService, UtilsService } from "../../services";
import { ReservationActions } from "./ReservationActions";

export interface Props {
  reservation: any;
  selectReservation: (uid: string) => void;
}

const columnStyle = {
  marginInline: 10,
};

const buttonStyle = {
  fontSize: 10,
};

export const ReservationItem: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [backgroundColor, setBackgroundColor] = useState<string>();
  const [color, setColor] = useState<string>();
  const [reservationState, setReservationState] = useState<string>(
    props.reservation.estado_reservacion
  );
  const [loading, setLoading] = useState<number>(-1);
  const [entityName, setEntityName] = useState<string>("...");

  useEffect(() => {
    globalProvider
      .getEntityName(props.reservation.Entidades_id)
      .then((name) => {
        setEntityName(name);
      })
      .catch((error) => {});

    switch (reservationState) {
      case "pendiente":
        {
          setColor(colors.carbon);
          setBackgroundColor(colors.warning);
        }
        break;
      case "confirmada":
        {
          setColor(colors.carbon);
          setBackgroundColor(colors.darkSuccess);
        }
        break;
      case "cancelada":
        {
          setColor(colors.gray);
          setBackgroundColor(colors.dangerLight);
        }
        break;
      case "realizada":
        {
          setColor(colors.carbon);
          setBackgroundColor(colors.lightgray);
        }
        break;
      default:
        {
          setColor(colors.carbon);
          setBackgroundColor(colors.lightgray);
        }
        break;
    }
  }, [reservationState]);

  const updateState = (type: 0 | 1 | 2 | 3) => {
    setLoading(type);
    let newState: string = "";
    let updateFn: any;
    switch (type) {
      case 0:
        {
          newState = "pendiente";
          updateFn = ReservationService.toPending;
        }
        break;
      case 1:
        {
          newState = "confirmada";
          updateFn = ReservationService.confirm;
        }
        break;
      case 2:
        {
          newState = "cancelada";
          updateFn = ReservationService.cancel;
        }
        break;
      case 3:
        {
          newState = "realizada";
          updateFn = ReservationService.finish;
        }
        break;
    }

    updateFn(props.reservation.Entidades_id, props.reservation.id)
      .then((data: any) => {
        setReservationState(newState);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(-1);
      });
  };

  const selectReservation = () => {
    props.selectReservation(props.reservation.uid);
  };

  return (
    <tr>
      <td onClick={selectReservation} style={columnStyle}>
        {props.reservation.uid}
      </td>
      <td onClick={selectReservation} style={columnStyle}>
        {props.reservation.email_cliente}
      </td>
      <td onClick={selectReservation}>{entityName}</td>
      <td
        onClick={selectReservation}
        style={{
          ...columnStyle,
          fontVariantCaps: "all-small-caps",
          backgroundColor: backgroundColor,
          color: color,
        }}
      >
        {reservationState}
      </td>
      <td onClick={selectReservation} style={columnStyle}>
        {UtilsService.parseDate(new Date(props.reservation.fecha_creacion))}
      </td>
      <td onClick={selectReservation} style={columnStyle}>
        {UtilsService.parseDate(new Date(props.reservation.fecha_reservacion))}
      </td>
      <td onClick={selectReservation} style={columnStyle}>
        ${props.reservation.precio_total_reserva}
      </td>
      <td style={columnStyle}>
        <ReservationActions
          entityId={props.reservation.Entidades_id}
          reservationId={props.reservation.id}
          setReservationState={setReservationState}
          state={props.reservation.estado_reservacion}
        />
      </td>
    </tr>
  );
};
