import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "..";
import {
  addShadow,
  colors,
  commonCard,
  commonCardM,
  commonCardXL,
  styleConstants,
} from "../../config";
import {
  AdditionalsXReservationExt,
  Reservation,
  ResourcesXReservationExt,
} from "../../interfaces";

export interface Props {
  entityName: string;
  reservation: Reservation;
  resources: ResourcesXReservationExt[];
  additionals?: AdditionalsXReservationExt[];
  totalPrice: number;
  submitReservation?: () => any;
  removeResource?: (resourceId: number) => void;
  emptyMessage: string;
  goToResource: (res: any) => void;
}

export const ReservationDetailsCard: React.FunctionComponent<Props> = (
  props: Props
) => {
  return (
    <div
      style={{
        ...commonCardM
      }}
    >
      {props.resources.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>
            Para el {props.reservation.fecha_reservacion} en {props.entityName}
          </h6>
          <div
            style={{
              marginBottom: 20,
            }}
          >
            {props.resources.map((res, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: colors.gray,
                    borderBottomStyle: "solid",
                    marginBottom: 5,
                    padding: 5,
                  }}
                >
                  <div
                    onClick={() => {
                      props.goToResource(res.Recursos_id);
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: colors.carbon,
                        fontSize: 18,
                      }}
                    >
                      {res.nombre} x {res.cantidad_reservada}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: colors.darkerGray,
                        fontSize: 15,
                      }}
                    >
                      de {res.hora_inicio_recurso_reservado} a{" "}
                      {res.hora_final_recurso_reservado}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      //justifyContent: 'flex-end',
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "bold", fontSize: 20, margin: 0 }}>
                      {" "}
                      ${res.precio_total_reserva}
                    </p>
                    {props.removeResource && (
                      <Button
                        style={{
                          borderRadius: 50,
                          paddingBlock: 0,
                          paddingInline: 6,
                          marginInline: 5,
                        }}
                        variant="outline-danger"
                        onClick={() => {
                          //REVIEW!!! ASKING 2 TIMES FOR PROPS.REMOVERESOURCE
                          if (props.removeResource) {
                            props.removeResource(res.Recursos_id);
                          }
                        }}
                      >
                        <Icon name={"trash"} size={13} />
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <p style={{ fontSize: 18, marginRight: 5, marginTop: 5 }}>Total</p>
            <p style={{ fontSize: 25, fontWeight: "bold" }}>
              ${props.totalPrice}
            </p>
          </div>
          {props.submitReservation && (
            <Button
              style={{
                alignSelf: "flex-end",
                borderRadius: 10,
              }}
              variant="success"
              onClick={props.submitReservation}
            >
              Reservar
            </Button>
          )}
        </div>
      ) : (
        <p>{props.emptyMessage}</p>
      )}
    </div>
  );
};
