import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { config } from "../../config";
import { globalProvider, userProvider } from "../../providers";
import { ReservationService } from "../../services";

export interface Props {
  state: string;
  entityId: number;
  reservationId: number;
  setReservationState: (state: string) => any;
}
const buttonStyle = {
  fontSize: 10,
};
export const ReservationActions: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [loading, setLoading] = useState<number>(-1);
  const authorized = props.entityId == userProvider.getEntityId;

  const updateState = (type: 0 | 1 | 2 | 3 | 4) => {
    setLoading(type);
    let newState: string = "";
    let updateFn: any;
    switch (type) {
      case 0:
        {
          newState = "pendiente";
          updateFn = ReservationService.toPending;
        }
        break;
      case 1:
        {
          newState = "confirmada";
          updateFn = ReservationService.confirm;
        }
        break;
      case 2:
        {
          newState = "cancelada";
          updateFn = ReservationService.cancel;
        }
        break;
      case 3:
        {
          newState = "realizada";
          updateFn = ReservationService.finish;
        }
        break;
      case 4: {
        newState = "rechazada";
        updateFn = ReservationService.deny;
      }
    }

    globalProvider.showAlert({
      title: "Actualizar estado?",
      message:
        "Seguro que queres actualizar el estado de "+config.getPlaceholders.reservation.singular.the+" "+config.getPlaceholders.reservation.singular.label+" a '" +
        newState +
        "'?",
      type: "info",
      alertType: "modal",
      onHideCB: () => {
        setLoading(-1);
      },
      buttons: [
        {
          action: () => {
            globalProvider.closeAlertModal();
            confirmUpdate(updateFn, newState);
          },
          title: "Actualizar",
          type: "info",
        },
        {
          action: () => {
            globalProvider.closeAlertModal();
            setLoading(-1);
          },
          title: "Cancelar",
          type: "error",
        },
      ],
    });
  };

  const confirmUpdate = (updateFn: any, newState: string) => {
    updateFn(props.entityId, props.reservationId)
      .then((data: any) => {
        props.setReservationState(newState);
        globalProvider.showAlert({
          message: config.getPlaceholders.reservation.singular.label+" actualizada exitosamente!",
          type: "info",
          alertType: "popup",
        });
      })
      .catch((error: any) => {
        console.error(error);
        globalProvider.showAlert({
          message: "No pudimos actualizar "+config.getPlaceholders.reservation.singular.the+" "+config.getPlaceholders.reservation.singular.label+"...",
          type: "error",
          alertType: "popup",
        });
      })
      .finally(() => {
        setLoading(-1);
      });
  };

  return (
    <ButtonGroup size={"sm"}>
      {userProvider.getIsLoggued ? (
        authorized && (
          <>
            <Button
              disabled={loading >= 0 || props.state == "cancelada"}
              variant="success"
              style={buttonStyle}
              onClick={() => updateState(1)}
            >
              {loading == 1 ? "Cargando..." : "Confirmar"}
            </Button>
            <Button
              disabled={loading >= 0 || props.state == "cancelada"}
              variant="primary"
              style={buttonStyle}
              onClick={() => updateState(0)}
            >
              {loading == 0 ? "Cargando..." : "Pendiente"}
            </Button>
            <Button
              disabled={loading >= 0 || props.state == "cancelada"}
              variant="info"
              style={buttonStyle}
              onClick={() => updateState(3)}
            >
              {loading == 3 ? "Cargando..." : "Finalizar"}
            </Button>
            <Button
              disabled={loading >= 0 || props.state == "cancelada"}
              variant="danger"
              style={buttonStyle}
              onClick={() => updateState(4)}
            >
              {loading == 3 ? "Cargando..." : "Rechazar"}
            </Button>
          </>
        )
      ) : (
        <Button
          disabled={loading >= 0 || props.state == "rechazada"}
          variant="danger"
          style={buttonStyle}
          onClick={() => updateState(2)}
        >
          {loading == 2 ? "Cargando..." : "Cancelar"}
        </Button>
      )}
    </ButtonGroup>
  );
};
