import React from 'react';
import {Spinner as Spin} from 'react-bootstrap'
import { colors } from '../../config';

export interface Props{
    animation?: 'border' | 'grow',
    size?:'sm',
    style?:any

}
export const Spinner:React.FunctionComponent<Props>=(props:Props)=>{
    return <Spin
        animation={props.animation? props.animation : 'border'}
        size={props.size && 'sm'}
        style={{
            color: colors.primary,
        ...props.style   
        }}
    />

}