import React from "react";
import { colors } from "../../config";
import { FieldProps } from "./Form";
import { Error } from "./Error";
import { Form as FormBoot } from "react-bootstrap";

export const SwitchField: React.FunctionComponent<FieldProps> = (
  props: FieldProps
) => {
  return (
    <div
      style={{
        marginBottom: 10,
      }}
    >
      <div
        style={{
          paddingVertical: 5,
          paddingHorizontal: 10,
          flexDirection: "row",
          ...props.style,
        }}
      >
        <FormBoot.Check
          disabled={!props.editable}
          type="switch"
          id="form-switch"
          label={props.label}
          placeholder={props.placeHolder}
          checked={props.value}
          onChange={(e)=>{
            props.handleChange(e.target.checked);
          }}
          onChangeCapture={(e: any) => {
            if (props.onChangeCallback) props.onChangeCallback(e.target.checked);
          }}
        />

        {props.touched && props.error && <p>{props.error}</p>}
      </div>
      <Error
        error={props.error}
        style={props.errorStyle}
        touched={props.touched}
      />
    </div>
  );
};
