import React from "react";
import { colors } from "../../config";
export interface Props {
  items: {
    variable: any;
    variableStyle?: any;
    value: any;
    valueStyle?: any;
  }[];
}
export const Ticket: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.items.map(
        ({ variable, variableStyle, value, valueStyle }, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              flexWrap: 'wrap',
              borderWidth: 1,
              borderRadius: 10,
              borderColor: colors.gray,
              borderBottomStyle: "solid",
              marginBottom: 5,
              paddingInline:10
            }}
          >
            <p style={{marginBlock:2, marginRight: 50, fontWeight:'bold', ...variableStyle }}>{variable}</p>
            <p style={{marginBlock:2, ...valueStyle }}>{value}</p>
          </div>
        )
      )}
    </div>
  );
};
