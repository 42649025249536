import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import { Icon } from "..";

export interface ButtonsBase {
  title: string;
  icon?: string;
  call: () => void;
  variant?: string;
  badge?: {
    value: number;
    backgroundColor?: string;
    color?: string;
  };
}

export interface BodyButtonsProps extends ButtonsBase {
  isDropdown?: boolean;
  dropdownOptions?: BodyButtonsProps[];
}

export interface HeaderProps {
  title?: string;
  subtitle?: string;
  button?: {
    title: string;
    icon?: string;
    call: () => void;
  };
}

export interface Props {
  show: boolean;
  close: () => void;
  header?: HeaderProps;
  bodyButtons?: BodyButtonsProps[];
  footerButtons?: ButtonsBase[];
}

export const Drawer: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <Offcanvas
        show={props.show}
        scroll={true}
        backdrop={true}
        onHide={props.close}
      >
        <Offcanvas.Header closeButton>
          <div
            style={{
              minHeight: "100%",
            }}
          >
            <Offcanvas.Title>{props.header?.title}</Offcanvas.Title>
            <h6>{props.header?.subtitle}</h6>
            {props.header?.button && (
              <Button
                onClick={props.header.button.call}
                variant="outline-primary"
              >
                {props.header?.button.title}
              </Button>
            )}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.bodyButtons?.map((button, index) => {
              return (
                <Button
                  style={{
                    position: "relative",

                    margin: 5,
                    borderWidth: 0,
                    textAlign: "left",
                    //color:'black'
                  }}
                  key={index}
                  onClick={() => {
                    button.call();
                    props.close();
                  }}
                  variant={button.variant}
                >
                  {button.badge && (
                    <div
                      style={{
                        color: button.badge.color
                          ? button.badge.color
                          : "white",
                        backgroundColor: button.badge.backgroundColor
                          ? button.badge.backgroundColor
                          : "red",
                        borderRadius: 100,
                        position: "absolute",
                        width: 20,
                        height: 20,
                        right: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {button.badge.value}
                    </div>
                  )}
                  <Icon name={button.icon ? button.icon : ""} />
                  {"    "}
                  {button.title}
                </Button>
              );
            })}
          </div>
          <div
            style={{
              bottom: 10,
              left: 5,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.footerButtons?.map((button, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => {
                    button.call();
                    props.close();
                  }}
                  style={{
                    margin: 5,
                    borderWidth: 0,
                    textAlign: "left",
                  }}
                  variant={button.variant}
                >
                  <Icon name={button.icon ? button.icon : ""} />
                  {"    "}
                  {button.title}
                </Button>
              );
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
