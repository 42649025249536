import {config} from '../config';

type HTTP_METHODS =  "GET" | "POST" | "PUT" | "PATCH" |  "DELETE";

export const FetchService = (() => {
  const serialize = (obj:any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };

  const callApi = async ({
    endpoint,
    method = 'GET',
    formData = false,
    data = {},
    customHeaders = {},
    auth = undefined, 
  }:{
    endpoint:string,
    method: HTTP_METHODS,
    formData?:boolean,
    data?:any,
    customHeaders?:any,
    auth?:string
  }) => {

    if(!auth){
      auth = "Bearer " + localStorage.getItem('at');
    }
    customHeaders = {
      ...customHeaders,
      Authorization: auth
    };

    return doFetch({
      url: config.getAPI_URL + endpoint,
      method: method,
      data: data,
      customHeaders: customHeaders,
      formData: formData,
    });
  };

  const doFetch = async ({
    url,
    method = "GET",
    formData = false,
    data = {},
    customHeaders = {},
  }:{
    url:string,
    method: HTTP_METHODS,
    data?:any,
    customHeaders?:any,
    formData:boolean
  }) => {
    let queryParams = '';

    let fetchOpt: any = {};
    fetchOpt.method = method ? method : 'GET';
    if (fetchOpt.method.toUpperCase() != 'GET') {
      if (formData) {
        let form = new FormData();
        for (const name in data) {
          if (Array.isArray(data[name])) {
            data[name].forEach((element:any) => {
              form.append(name, element);
            });
          } else {
            form.append(name, data[name]);
          }
        }
        fetchOpt.body = form;
      } else {
        fetchOpt.body = JSON.stringify(data);
      }
    } else {
      queryParams = '?' + serialize(data);
    }
    let headers = {};
    if (formData) {
      headers = {};
    } else {
      headers = {
        'Content-Type': 'application/json',
      };
    }

    headers = {...headers, ...customHeaders};

    fetchOpt.headers = headers;

    return new Promise<any>((resolve, reject) => {
      fetch(url + queryParams, fetchOpt)
        .then(async(response) => {
          if (response.ok) {
            if( response.status == 204){
              resolve({});
            }else{
              let res = await response.json();
              resolve(res);
            }
  
          }else{
            let res = await response.json();
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  };
  return {
    serialize: serialize,
    doFetch: doFetch,
    callApi: callApi,
  };
})();
