import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AlertButton, AlertType } from "../../interfaces";

export interface Props {
  show: boolean;
  close: () => void;
  title?: string;
  message: string;
  type: AlertType;
  timeout?: number;
  okCB?: () => void;
  buttons?: AlertButton[];
  onHideCB?: () => void;
}

export const AlertModal: React.FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    if (props.show && props.timeout) {
      setTimeout(() => {
        if (props.onHideCB) props.onHideCB();
        props.close();
      }, props.timeout);
    }
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      style={{
        borderRadius: 10,
      }}
      onHide={() => {
        if (props.onHideCB) props.onHideCB();
        props.close();
      }}
    >
      <Modal.Header closeButton> 
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.message}</p>

        {props.buttons ? (
          props.buttons.map((button) => (
            <Button
              style={{
                borderRadius: 10,
                margin: 5,
              }}
              variant={button.type == "info" ? "primary" : "danger"}
              onClick={button.action}
            >
              {button.title}
            </Button>
          ))
        ) : (
          <Button
            variant={props.type == "info" ? "primary" : "danger"}
            onClick={() => {
              if (props.okCB) props.okCB();
              props.close();
            }}
          >
            Ok
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};
