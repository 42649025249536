import React from "react";
import { FloatingLabel } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { Form } from "../../components";
import { addBorders, addLightShadow, addShadow, colors, styleConstants } from "../../config";
import { userProvider } from "../../providers";
import { LoginService } from "../../services";

export interface Props extends RouteComponentProps {}

interface State {
  email: string;
  password: string;
  authError: boolean;
}

class LoginScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      authError: false,
      email: "",
      password: "",
    };
  }

  login = (values: {
    Email: string;
    Contraseña: string;
    Recordarme: boolean;
  }) => {
    this.setState({
      authError: false,
    });

    userProvider
      .login(values.Email, values.Contraseña, values.Recordarme)
      .then(() => this.props.history.push("/home"))
      .catch(() => {
        this.setState({
          authError: true,
        });
      });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            padding: 20,
            borderRadius: 10,
            ...addLightShadow,
            //backgroundColor: "white",
            maxWidth: 350,
            margin:5
          }}
        >
          <Form
            takeInitialValues={true}
            submit={{
              call: this.login,
              title: "LOGIN",
              style: { marginBottom: 0 },
              error: this.state.authError,
              errorMessage: "Usuario o Contraseña incorrectos",
              errorStyle: { alignSelf: "center", marginTop: 10 },
            }}
            inputs={[
              {
                constraints: [
                  { type: "email", message: "Formato de email incorrecto!" },
                  { type: "required", message: "Campo obligatorio." },
                ],
                initialValue: "",
                label: "Email",
                placeHolder: "Email",
                type: "text",
                valueType: "text",
                style: {},
              },
              {
                constraints: [
                  { type: "required", message: "Campo obligatorio." },
                ],
                initialValue: "",
                label: "Contraseña",
                placeHolder: "Contraseña",
                type: "text",
                valueType: "password",
                style: {},
              },
              {
                initialValue: true,
                label: "Recordarme",
                placeHolder: "Recordarme",
                type: "switch",
                valueType: "boolean",
                style: { fontColor: colors.gray, fontSize: 15 },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(LoginScreen);
