import React, { useEffect, useState } from "react";
import { ResourceCard } from "../../components";
import { config } from "../../config";
import { ResourceService } from "../../services";
export interface Props {
  resource: any;
  actionTitle: string;
  action: (resource: any) => void;
  moreInfo: (resource: any) => void;
}

export const ResourcePreview: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [defaultImage, setDefaultImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    ResourceService.getImages(
      props.resource.Entidades_id,
      props.resource.id,
      true
    )
      .then((defImg) => {
        setDefaultImage(config.getAPI_URL + "/uploads/" + defImg.image_path);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <ResourceCard
      key={props.resource.id}
      resource={props.resource}
      action={props.action}
      actionTitle={props.actionTitle}
      moreInfo={props.moreInfo}
      image={defaultImage}
    />
  );
};
