import { config } from "../../config";
import React from "react";
import { UtilsService } from "../../services";
import { Form } from "../Form/Form";
export interface Props {
  submit: (r: any) => any;
  resource?: any;
  error: boolean;
}

const formInputStyle = {};
export const ResourceForm: React.FunctionComponent<Props> = (props: Props) => {
  let resource: any = {};
  let type: string;
  let typeFirstCap: string;
  if (props.resource) {
    resource = {
      nombre: props.resource.nombre,
      descripcion: props.resource.descripcion,
      precio: props.resource.precio,
      precio_promocional: props.resource.precio_promocional,
      personas_habilitadas: props.resource.personas_habilitadas,
      cantidad_total: props.resource.cantidad_total,
      habilitado: props.resource.habilitado,
      max_turnos_reserva: props.resource.max_turnos_reserva,
      tiempo_turno: props.resource.tiempo_turno,
      fecha_creacion: props.resource.fecha_creacion,
      fecha_actualizacion: props.resource.fecha_actualizacion,
    };
    type = "actualizar";
    typeFirstCap = "Actualizar";
  } else {
    resource = {
      nombre: "",
      descripcion: "",
      precio: "",
      precio_promocional: "",
      personas_habilitadas: "",
      cantidad_total: "",
      habilitado: "",
      max_turnos_reserva: "",
      tiempo_turno: "",
      fecha_creacion: "",
      fecha_actualizacion: "",
    };
    type = "crear";
    typeFirstCap = "Crear";
  }
  return (
    <Form
      takeInitialValues={props.resource != undefined}
      submit={{
        call: props.submit,
        title: typeFirstCap + config.getPlaceholders.resource.singular.label,
        style: {},
        error: props.error,
        errorMessage:
          "No se pudo " +
          type +
          " " +
          config.getPlaceholders.resource.singular.the +
          " " +
          config.getPlaceholders.resource.singular.label +
          ".",
        errorStyle: {},
      }}
      inputs={[
        {
          label: "Nombre",
          placeHolder: "Nombre",
          style: formInputStyle,
          constraints: [
            { type: "required", message: "El nombre es obligatorio." },
            {
              type: "max",
              message: "45 caracteres como máximo",
              value: 45,
            },
          ],
          initialValue: resource.nombre,
          type: "text",
          valueType: "text",
        },
        {
          label: "Descripcion",
          placeHolder: "Descripcion",
          style: formInputStyle,
          constraints: [
            {
              type: "max",
              message: "300 caracteres como máximo",
              value: 300,
            },
          ],
          initialValue: resource.descripcion,
          type: "text",
          valueType: "text",
        },
        {
          label: "Precio",
          placeHolder: "Precio",
          style: formInputStyle,
          constraints: [
            { type: "required", message: "El precio es obligatorio." },
          ],
          initialValue: resource.precio,
          type: "text",
          valueType: "number",
        },
        {
          label: "Precio Promocional",
          placeHolder: "Precio Promocional",
          style: formInputStyle,
          initialValue: resource.precio_promocional,
          type: "text",
          valueType: "number",
        },
        {
          label: "Personas Habilitadas",
          placeHolder: "Personas Habilitadas",
          style: formInputStyle,
          constraints: [
            {
              type: "required",
              message: "La cantidad de personas habilitadas es obligatorio.",
            },
          ],
          initialValue: resource.personas_habilitadas,
          type: "text",
          valueType: "number",
        },
        {
          label: "Cantidad",
          placeHolder: "Cantidad",
          style: formInputStyle,
          constraints: [
            {
              type: "required",
              message: "La cantidad es obligatorio.",
            },
          ],
          initialValue: resource.cantidad_total,
          type: "text",
          valueType: "number",
        },
        {
          label: "Habilitado",
          placeHolder: "Habilitado",
          style: formInputStyle,
          constraints: [
            {
              type: "required",
              message: "El estado habilitado/deshabilitado es obligatorio.",
            },
          ],
          initialValue: resource.habilitado,
          type: "switch",
          valueType: "boolean",
        },
        {
          label:
            "Maximo # de turnos por " +
            config.getPlaceholders.reservation.singular.label,
          placeHolder:
            "Maximo # de turnos por " +
            config.getPlaceholders.reservation.singular.label,
          style: formInputStyle,
          constraints: [
            {
              type: "required",
              message:
                "El numero máximo de turnos por " +
                config.getPlaceholders.reservation.singular.label +
                " es obligatorio",
            },
          ],
          initialValue: resource.max_turnos_reserva,
          type: "text",
          valueType: "number",
        },
        {
          label: "Tiempo p/ turno",
          placeHolder: "Tiempo p/ turno",
          style: formInputStyle,
          constraints: [
            {
              type: "required",
              message: "El tiempo por turno es obligatorio",
            },
          ],
          extraValidations: [
            {
              title: "isTimeFormat",
              message: "El formato debe ser HH:MM:SS",
              validation: UtilsService.isTimeFormat,
            },
          ],
          initialValue: resource.tiempo_turno,
          type: "text",
          valueType: "text",
        },
        {
          label: "Fecha de Creacion",
          placeHolder: "Fecha de Creacion",
          style: formInputStyle,
          initialValue: resource.fecha_creacion,
          type: "text",
          valueType: "text",
          editable: false,
        },
        {
          label: "Ultima Actualizacion",
          placeHolder: "Ultima Actualizacion",
          style: formInputStyle,
          initialValue: resource.fecha_actualizacion,
          type: "text",
          valueType: "text",
          editable: false,
        },
      ]}
    />
  );
};
