// Formik x React Native example
import React, { useState } from "react";

import { Formik } from "formik";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { TextField } from "./TextField";
import { useEffect } from "react";
import { SwitchField } from "./SwitchField";
import { addBorders, styleConstants } from "../../config";
import { Error } from "./Error";
import { DropdownField } from "./DropdownField";

export interface FieldProps {
  label: string;
  value: any;
  type: ValueType;
  handleChange: (value: any) => any;
  handleBlur: (value: any) => any;
  error: any;
  touched: any;
  placeHolder: any;
  style: any;
  errorStyle: any;
  editable: boolean;
  onChangeCallback?: (value: any) => void;
}

type ValueType =
  | "text"
  | "number"
  | "boolean"
  | "password"
  | { value: any; label: string }[];
type FieldType = "text" | "checkbox" | "dropdown" | "switch";
type ConstraintType = {
  type: "required" | "email" | "max";
  value?: any;
  message: string;
};

type TransformType = (value: any, originalValue: any) => any;
type ExtraValidation = {
  title: string;
  message: string;
  validation: (value: any) => boolean;
};

interface InputField {
  type: FieldType;
  label: string;
  placeHolder: any;
  initialValue: any;
  valueType: ValueType;
  constraints?: ConstraintType[];
  transforms?: TransformType[];
  extraValidations?: ExtraValidation[];
  style?: any;
  errorStyle?: any;
  editable?: boolean;
  onChangeCallback?: (value: any) => void;
  children?: any;
}
export interface Props {
  /**
   * Value must be a conditional statement
   */
  constraint?: {
    type: "conditional";
    value: any;
    message: string;
  };
  inputs: InputField[];
  takeInitialValues?: boolean;
  style?: any;
  submit: {
    call: (values: any) => any;
    title: string;
    style?: any;
    error?: boolean;
    errorMessage?: string;
    errorStyle?: any;
  };
  disableSubmitButton?: boolean;
  resetOnSubmit?: boolean;
}

export const Form: React.FunctionComponent<Props> = (props: Props) => {
  const [initialValues, setInitialValues] = useState<any>({});
  const [takeInitialValues, setTakeInitialValues] = useState<boolean>(
    props.takeInitialValues ? props.takeInitialValues : false
  );
  const [validationSchema, setValidationSchema] = useState<any>({});
  const [submitIntents, setSubmitIntents] = useState<number>(0);
  const [errors, setErrors] = useState<any>();
  var submit = () => {};

  useEffect(() => {
    buildForm();
  }, []);

  const getYupField = (
    type: ValueType,
    constraints: ConstraintType[],
    transforms: TransformType[],
    extraValidations: ExtraValidation[]
  ) => {
    let yupField: any;
    switch (type) {
      case "number":
        yupField = Yup.number().typeError("El valor debe ser numérico").nullable(true);
        break;
      case "text":
        yupField = Yup.string().nullable();
        break;
      case "boolean":
        yupField = Yup.bool();
        break;
      case "password":
        yupField = Yup.string();
        break;
      default:
        yupField = Yup.string();
    }

    constraints.forEach((constraint) => {
      switch (constraint.type) {
        case "email":
          yupField = yupField.email(constraint.message);
          break;
        case "max":
          yupField = yupField.max(constraint.value, constraint.message);
          break;
        case "required":
          yupField = yupField.required(constraint.message);
      }
    });

    transforms.forEach((transform) => {
      yupField = yupField.transform((value: any, originalValue: any) => {
        return transform(value, originalValue);
      });
    });

    extraValidations.forEach((extraValidation) => {
      yupField = yupField.test(
        extraValidation.title,
        extraValidation.message,
        extraValidation.validation
      );
    });

    return yupField;
  };

  const buildForm = () => {
    let validationSchemaObject: any = {};
    let initialValuesObject: any = {};

    props.inputs.forEach((input) => {
      initialValuesObject[input.label] = input.initialValue;
      validationSchemaObject[input.label] = getYupField(
        input.valueType,
        input.constraints ? input.constraints : [],
        input.transforms ? input.transforms : [],
        input.extraValidations ? input.extraValidations : []
      );
    });

    setInitialValues(initialValuesObject);
    setValidationSchema(validationSchemaObject);
  };

  const setSubmit = (submitFunc: any) => {
    submit = submitFunc;
  };

  const canSubmit = () => {
    return errors
      ? Object.entries(errors).length == 0 &&
          (props.constraint?.value === true || props.constraint === undefined)
      : false;
  };

  const trySubmit = () => {
    setSubmitIntents(submitIntents + 1);
    if (canSubmit()) {
      submit();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(validationSchema)}
      onSubmit={(values, { resetForm }) => {
        let ret = props.submit.call(values)
        if (props.resetOnSubmit && ret != false) {
          let vals: any = {};
          props.inputs.forEach((input) => {
            vals[input.label] = "";
          });
          resetForm({ values: vals });
        }
        
      }}
      
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => {
        setErrors(errors);
        setSubmit(handleSubmit);
        return (
          <div
            style={{
              width: "100%",
              maxWidth: styleConstants.containerMaxWidth,
              padding: 10,
              ...props.style,
            }}
          >
            {props.inputs.map((input, index) => {
              if (takeInitialValues && submitIntents == 0) {
                values[input.label] = input.initialValue;
                if (index == props.inputs.length - 1) {
                  setTakeInitialValues(false);
                }
              }
              switch (input.type) {
                case "text":
                  return (
                    <>
                      <TextField
                        key={index}
                        type={input.valueType}
                        editable={
                          input.editable === undefined ? true : input.editable
                        }
                        style={{
                          ...input.style,
                        }}
                        onChangeCallback={input.onChangeCallback}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values[input.label]}
                        touched={touched[input.label] || submitIntents > 0}
                        error={errors[input.label]}
                        label={input.label}
                        placeHolder={input.placeHolder}
                        errorStyle={input.errorStyle}
                      />
                      {input.children}
                    </>
                  );
                  break;
                case "dropdown":
                  return (
                    <>
                      <DropdownField
                        key={index}
                        type={input.valueType}
                        editable={
                          input.editable === undefined ? true : input.editable
                        }
                        style={{ ...input.style }}
                        handleChange={(value: any) => {
                          setFieldValue(input.label, value);
                        }}
                        handleBlur={handleBlur}
                        value={values[input.label]}
                        touched={touched[input.label] || submitIntents > 0}
                        error={errors[input.label]}
                        label={input.label}
                        placeHolder={input.placeHolder}
                        errorStyle={input.errorStyle}
                      />
                      {input.children}
                    </>
                  );
                  break;
                case "checkbox":
                  break;
                case "switch":
                  return (
                    <>
                      <SwitchField
                        key={index}
                        type={input.valueType}
                        editable={
                          input.editable === undefined ? true : input.editable
                        }
                        style={{
                          ...input.style,
                        }}
                        handleChange={(value: any) => {
                          setFieldValue(input.label, value);
                        }}
                        handleBlur={handleBlur}
                        value={values[input.label]}
                        touched={touched[input.label] || submitIntents > 0}
                        error={errors[input.label]}
                        label={input.label}
                        placeHolder={input.placeHolder}
                        errorStyle={input.errorStyle}
                      />
                      {input.children}
                    </>
                  );
                  break;
              }
            })}
            <Error
              error={props.constraint?.message}
              touched={props.constraint?.value === false}
            />
            {(props.disableSubmitButton == undefined ||
              props.disableSubmitButton == false) && (
              <Button
                style={{
                  marginTop: 20,
                  ...props.submit.style,
                }}
                onClick={trySubmit}
                variant={canSubmit() ? "success" : "secondary"}
              >
                {props.submit.title}
              </Button>
            )}
            <Error
              error={props.submit.errorMessage}
              touched={props.submit.error}
              style={props.submit.errorStyle}
            />
          </div>
        );
      }}
    </Formik>
  );
};
