import React from "react";
import { Carousel } from "react-bootstrap";
import { Icon } from "..";
import { addBorders } from "../../config";

export interface Props {
  images: string[];
  height: any;
  width: any;
}

export const ImageCarousel: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Carousel
      style={{
        minHeight: 300,
        maxHeight: "85vh",
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      {props.images.map((image, index) => {
        return (
          <Carousel.Item key={index}>
            <img
              style={{
                width: props.width,
                height: props.height,
              }}
              src={image}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};
