import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "..";
import { Image } from "../../interfaces";

export interface Props {
  id: number;
  image: string;
  clear: (id: number) => void;
}

export const ImageItem: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      key={props.id}
      style={{
        position: "relative",
        margin:5
      }}
    >
      <Button
        onClick={() => {
          props.clear(props.id);
        }}
        variant="danger"
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          borderRadius: 50,
        }}
      >
        <Icon name="clear" size={12} />
      </Button>
      <img
        style={{
          width: 200,
          height: 200,
          objectFit: "contain",
        }}
        src={props.image}
      />
    </div>
  );
};
