import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  RouteComponentProps,
  withRouter,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  HomeScreen,
  EntityScreen,
  CartScreen,
  ReservationLobby,
  LoginScreen,
  EditResourceScreen,
  NewResourceScreen,
  EditResourceScheduleScreen,
  ResourceScreen,
} from "../screens";
import { ScreenTemplate, Layout } from "../components";
import { observer } from "mobx-react";
import { cartProvider, globalProvider, userProvider } from "../providers";
import { ButtonsBase } from "../components/Drawer/Drawer";
import ReservationScreen from "../screens/Reservation/ReservationScreen";
import { colors, config } from "../config";

export interface Props extends RouteComponentProps {}

const MainNavigation: React.FunctionComponent<Props> = (props: Props) => {
  let { path, url } = useRouteMatch();
  const [sections, setSections] = useState<ButtonsBase[]>([]);
  const [footers, setFooters] = useState<any[]>([]);

  useEffect(() => {
    let sections: ButtonsBase[] = [
      {
        title: "Home",
        call: () => {
          props.history.push("/home");
        },
        icon: "home-outline",
        variant: "outline-primary",
      },
      {
        title:
          "Buscar " +
          config.getPlaceholders.reservation.singular.a +
          " " +
          config.getPlaceholders.reservation.singular.label,
        call: () => {
          props.history.push("/reservations");
        },
        icon: "search",
        variant: "outline-primary",
      },
    ];
    let footers: ButtonsBase[] = [];

    if (userProvider.getIsLoggued) {
      footers.push({
        title: "Logout",
        call: () => {
          props.history.push("/home");
          userProvider.logout();
        },
        variant: "outline-danger",
        icon: "logout",
      });
    } else {
      sections.push({
        title: "Mi " + config.getPlaceholders.reservation.singular.label,
        call: () => {
          props.history.push("/cart");
        },
        icon: "shopping-cart-outline",
        variant: "outline-primary",
        badge:
          cartProvider.getItemAmount > 0
            ? {
                value: cartProvider.getItemAmount,
              }
            : undefined,
      });
      footers.push({
        title: "Login",
        call: () => {
          props.history.push("/login");
        },
        variant: "outline-primary",
        icon: "logout",
      });
    }

    setSections(sections);
    setFooters(footers);
  }, [userProvider.getIsLoggued, cartProvider.getItemAmount]);

  return (
    <Switch>
      <Layout
        backgroundColor={colors.backgroundColor}
        header={{
          title: config.getPlaceholders.reservation.plural.extendedLabel,
          //subtitle: "",
        }}
        sections={sections}
        footer={footers}
      >
        {userProvider.getIsLoggued ? (
          <>
            <Route path={"/home"}>
              <EntityScreen id={userProvider.getEntityId} />
            </Route>

            <Route exact path={"/resource/:resourceId/edit"}>
              <EditResourceScreen
                resource={globalProvider.getParam("EditResourceScreen")}
              />
            </Route>

            <Route exact path={"/resource/:resourceId/schedule/edit"}>
              <EditResourceScheduleScreen />
            </Route>

            <Route exact path={"/resource/0/new/"}>
              <NewResourceScreen />
            </Route>
          </>
        ) : (
          <>
            <Route path={"/home"}>
              <HomeScreen />
            </Route>
          </>
        )}

        <Route exact path={"/resource/:resourceId"}>
          <ResourceScreen
            resource={globalProvider.getParam("ResourceScreen")}
          />
        </Route>

        <Route path={"/entity/*"}>
          <EntityScreen />
        </Route>

        <Route path={"/reservations"}>
          <ReservationLobby />
        </Route>

        <Route path={"/reservation/:reservationUID"}>
          <ReservationScreen />
        </Route>

        <Route path={"/cart"}>
          <CartScreen />
        </Route>

        <Route path={"/login"}>
          <LoginScreen />
        </Route>

        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
      </Layout>
    </Switch>
  );
};

export default withRouter(observer(MainNavigation));
