import React from "react";
import { Button, Card } from "react-bootstrap";
import { addBorders, addShadow, colors, config, styleConstants } from "../../config";
import { UtilsService } from "../../services";

export interface Props {
  resource: any;
  image?: string;
  actionTitle: string;
  action: (resource: any) => void;
  moreInfo: (resource: any) => void;
}

export const ResourceCard: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Card
      key={props.resource.id}
      style={{
        width: "100%",
        maxWidth: 350,
        //width: "18rem",
        margin: 5,
        ...addShadow,
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <img
        style={{
          maxWidth: "100%",
          height: 300,
          objectFit: "contain",
        }}
        onClick={() => {
          props.moreInfo(props.resource);
        }}
        src={props.image ? props.image : config.getAPI_URL + "/uploads/default.png"}
      />
      {/** 
      <Card.Img
        className={"card-image"}
        onClick={() => {
          props.moreInfo(props.resource);
        }}
        variant="top"
        src={
          props.image
            ? props.image
            : config.getAPI_URL + "/uploads/default.png"
        }
      />
      */}

      <Card.Body>
        <div
          onClick={() => {
            props.moreInfo(props.resource);
          }}
        >
          <Card.Title>{props.resource.nombre}</Card.Title>
          <Card.Text>
            <>
              <p
                style={{
                  color: colors.dangerDark
                }}
              >{!props.resource.habilitado && "DESHABILITADO"}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {props.resource.precio_promocional && (
                  <p
                    style={{
                      color: colors.primary,
                      marginRight: 5,
                    }}
                  >
                    ${props.resource.precio_promocional}
                  </p>
                )}
                <p
                  style={{
                    color: colors.primary,
                    marginRight: 5,
                    textDecoration: props.resource.precio_promocional ? "line-through" : "none",
                    textDecorationColor: colors.carbon,
                  }}
                >
                  ${props.resource.precio}
                </p>
                <p
                  style={{
                    color: colors.darkerGray,
                    fontSize: 15,
                  }}
                >
                  p/
                  {UtilsService.parseStringTime(props.resource.tiempo_turno)}
                </p>
              </div>{" "}
            </>
          </Card.Text>
        </div>
        <Button
          onClick={() => {
            props.moreInfo(props.resource);
          }}
          style={{ margin: 3, borderRadius: 10, fontSize: 12 }}
          variant="outline-primary"
        >
          Más info
        </Button>
        <Button
          style={{ margin: 3, borderRadius: 10, fontSize: 12 }}
          variant="outline-primary"
          onClick={() => {
            props.action(props.resource);
          }}
        >
          {props.actionTitle}
        </Button>
      </Card.Body>
    </Card>
  );
};
