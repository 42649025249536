import { observer } from "mobx-react";
import { config } from "../../config";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, ReservationDetailsCard } from "../../components";
import { addBorders, addShadow, colors } from "../../config";
import { cartProvider, globalProvider } from "../../providers";
import { ReservationService, StorageService } from "../../services";

export interface Props extends RouteComponentProps {}
interface State {
  showConfirmReservationModal: boolean;
}

class CartScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showConfirmReservationModal: false,
    };
  }

  tryToSubmit = (values: any) => {
    this.setState({
      showConfirmReservationModal: false,
    });

    cartProvider.setEmail = values.Email;
    let entityId: any = cartProvider.getEntityId;

    globalProvider.setIsLoading = true;
    ReservationService.post(entityId, {
      Reservaciones: cartProvider.getReservation,
      Recursos_Por_Reservaciones: cartProvider.getResources,
    })
      .then((data) => {
        if (data.uid) {
          cartProvider.cleanCart();
          StorageService.addReservationUID(data.uid);
          globalProvider.showAlert({
            title: "Tu "+config.getPlaceholders.reservation.singular.label+" está "+config.getPlaceholders.reservation.singular.ready+"!",
            message:
              "Enviamos tu " +config.getPlaceholders.reservation.singular.label+" "+config.getPlaceholders.entity.singular.toThe+ " " + config.getPlaceholders.entity.singular.label +". Cuando "+config.getPlaceholders.reservation.singular.the +" confirmen te vamos a enviar una notificación.",
            type: "info",
            alertType: "modal",
            okCB: () => {
              this.props.history.push("/home");
            },
          });
        }
      })
      .catch((error) => {
        console.error("ERror!!: ", error);
      })
      .finally(() => {
        globalProvider.setIsLoading = false;
      });
  };

  goToResource = (resourceId: any) => {
    this.props.history.push("/resource/" + resourceId);
  };

  render() {
    return (
      <div
        style={{
          padding:20
        }}
      >
        <Modal
          show={this.state.showConfirmReservationModal}
          onHide={() => {
            this.setState({
              showConfirmReservationModal: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ingresar Email</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              takeInitialValues={false}
              submit={{
                call: this.tryToSubmit,
                title: "Reservar",
              }}
              inputs={[
                {
                  constraints: [
                    { type: "email", message: "Formato de email no válido" },
                  ],
                  initialValue: "",
                  label: "Email",
                  placeHolder: "agustin@gmail.com",
                  type: "text",
                  valueType: "text",
                },
              ]}
            />
          </Modal.Body>
        </Modal>
        <h1>{config.getPlaceholders.reservation.singular.label} Actual</h1>
        {cartProvider.getItemAmount > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReservationDetailsCard
              entityName={cartProvider.getEntityName}
              reservation={cartProvider.getReservation}
              resources={cartProvider.getResources}
              additionals={cartProvider.getAdditionals}
              totalPrice={cartProvider.getTotalPrice}
              submitReservation={() => {
                this.setState({
                  showConfirmReservationModal: true,
                });
              }}
              emptyMessage={"No hay items en tu carrito!"}
              removeResource={cartProvider.removeItem}
              goToResource={this.goToResource}
            />
          </div>
        ) : (
          <h4>Tu carrito esta vacío!</h4>
        )}
      </div>
    );
  }
}

export default withRouter(observer(CartScreen));
