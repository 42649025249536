import React from "react";
import { Modal } from "react-bootstrap";
import { Schedule } from "..";

export interface Props {
  isLoading: boolean;
  fetchSchedule: () => Promise<any>;
  show: boolean;
  close: () => void;
  style?: any;
  title?: string;
}
export const SchedulePreviewModal: React.FunctionComponent<Props> = (
  props: Props
) => {
  return (
    <Modal fullscreen={"sm-down"} show={props.show} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Schedule
          isLoading={props.isLoading}
          fetchSchedule={props.fetchSchedule}
          editable={false}
          style={props.style}
        />
      </Modal.Body>
    </Modal>
  );
};
