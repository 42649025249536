import React from "react";
import { Button, ButtonGroup, Carousel } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { ImageCarousel, Schedule, SeatSelectorModal, Spinner, Ticket } from "../../components";
import { addBorders, colors, commonCard, commonCardM, commonCardSM, commonCardXL, config, styleConstants } from "../../config";
import { AdditionalsXReservationExt, ResourceImage, ResourcesXReservationExt } from "../../interfaces";
import { cartProvider, globalProvider, userProvider } from "../../providers";
import { ResourceService } from "../../services";
export interface Props extends RouteComponentProps {
  resource: any;
  images?: ResourceImage[];
}
interface State {
  resource: any;
  authorized: boolean;
  images: ResourceImage[];
  imgStrings: string[];
  showSeatSelectorModal: boolean;
  entityName: string;
  resourceTicketItems: any[];
  loadingResource: boolean;
  loadingSchedule: boolean;
}
class ResourceScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      resource: props.resource ? props.resource : undefined,
      authorized: props.resource ? props.resource.Entidades_id == userProvider.getEntityId : false,
      images: props.images ? props.images : [],
      imgStrings: [config.getAPI_URL + "/uploads/default.png"],
      showSeatSelectorModal: false,
      entityName: "",
      resourceTicketItems: [],
      loadingResource: false,
      loadingSchedule: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.setState({
      loadingResource: true,
    });
    if (!this.state.resource) {
      let urlParams = this.props.location.pathname.split("/");
      let resourceId: any = urlParams[2];

      await ResourceService.get(resourceId)
        .then((resource) => {
          this.setState({
            resource: resource,
            authorized: resource.Entidades_id == userProvider.getEntityId,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    globalProvider
      .getEntityName(this.state.resource.Entidades_id)
      .then((name) => {
        this.setState({
          entityName: name,
        });
      })
      .catch(() => {});

    this.fetchImages(this.state.resource.Entidades_id, this.state.resource.id);
    this.buildResourceTicket();
  };

  buildResourceTicket() {
    let ticketItems: any = [
      {
        variable: "Descripcion",
        value: this.state.resource.descripcion,
      },
      {
        variable: "Precio p/ Turno",
        value: "$" + this.state.resource.precio,
        valueStyle: {
          fontWeight: "bold",
          marginRight: 5,
          textDecoration: this.state.resource.precio_promocional ? "line-through" : "none",
          textDecorationColor: colors.carbon,
        },
      },
    ];

    if (this.state.resource.precio_promocional) {
      ticketItems.push({
        variable: "Precio p/ Turno (promo)",
        value: "$" + this.state.resource.precio_promocional,
        valueStyle: {
          fontWeight: "bold",
        },
      });
    }

    ticketItems.push(
      {
        variable: "Duracion del turno",
        value: this.state.resource.tiempo_turno,
      },
      {
        variable: "Cantidad de Personas Habilitadas",
        value: this.state.resource.personas_habilitadas,
      },
      {
        variable: "Stock Total",
        value: this.state.resource.cantidad_total,
      },
      {
        variable: "Maxima cantidad de turnos por " + config.getPlaceholders.reservation.singular.label,
        value: this.state.resource.max_turnos_reserva,
      }
    );

    this.setState({
      resourceTicketItems: ticketItems,
    });
  }

  fetchImages = (entityId: number, resourceId: number) => {
    ResourceService.getImages(entityId, resourceId)
      .then((data = []) => {
        let imgs: ResourceImage[] = [];
        let imgStrings: string[] = [];
        data.forEach((img: ResourceImage) => {
          imgs.push({
            id: img.id,
            image_path: config.getAPI_URL + "/uploads/" + img.image_path,
            predeterminada: img.predeterminada,
            Recursos_id: img.Recursos_id,
          });
          imgStrings.push(config.getAPI_URL + "/uploads/" + img.image_path);
        });
        this.setState({
          images: imgs,
          imgStrings: imgStrings,
        });
      })
      .catch((error) => {
        console.error("No images for Resource...", error);
        this.setState({
          imgStrings: [config.getAPI_URL + "/uploads/default.png"],
        });
      })
      .finally(() => {
        this.setState({
          loadingResource: false,
        });
      });
  };

  fetchSchedule = () => {
    return new Promise((resolve, reject) => {
      //  this.setState({ loadingSchedule: true });

      ResourceService.getSchedule(this.state.resource.id)
        .then((data = []) => {
          resolve(data[0]);
        })
        .catch((error) => {
          reject();
        })
        .finally(() => this.setState({ loadingSchedule: false }));
    });
  };

  goToEditInfo = () => {
    globalProvider.setParam({
      for: "EditResourceScreen",
      value: this.state.resource,
    });
    this.props.history.push("/resource/" + this.state.resource.id + "/edit");
  };

  goToEditSchedule = () => {
    this.props.history.push("/resource/" + this.state.resource.id + "/schedule/edit");
  };

  goToEditImages = () => {};

  closeSeatSelectorModal = () => {
    this.setState({
      showSeatSelectorModal: false,
    });
  };

  addToReservation = () => {
    if (cartProvider.getEntityId && cartProvider.getEntityId != this.state.resource.Entidades_id) {
      globalProvider.showAlert({
        type: "error",
        title: "Estás en " + config.getPlaceholders.entity.singular.another + " " + config.getPlaceholders.entity.singular.label + "!",
        message:
          "Tu " +
          config.getPlaceholders.reservation.singular.label +
          " actual pertenece a " +
          config.getPlaceholders.entity.singular.another +
          " " +
          config.getPlaceholders.entity.singular.label +
          ", " +
          config.getPlaceholders.reservation.singular.the +
          " debes concretar antes de poder generar " +
          config.getPlaceholders.reservation.singular.a +
          " " +
          config.getPlaceholders.reservation.singular.label +
          " de " +
          config.getPlaceholders.entity.singular.another +
          " " +
          config.getPlaceholders.entity.singular.label,
      });
      console.error("Different entity on existing cart");
      return;
    }

    if (cartProvider.getResources.find((res) => res.Recursos_id == this.state.resource.id)) {
      globalProvider.showAlert({
        type: "error",
        title: "Repetido!",
        message:
          config.getPlaceholders.resource.singular.this +
          " " +
          config.getPlaceholders.resource.singular.label +
          " ya se encuentra en tu " +
          config.getPlaceholders.reservation.singular.label +
          " actual.",
      });

      console.error("Resource already on existing cart");
      return;
    }

    this.setState({
      showSeatSelectorModal: true,
    });
  };

  addItemToCart = (res: ResourcesXReservationExt, additional?: AdditionalsXReservationExt) => {
    cartProvider
      .addItem(this.state.resource.Entidades_id, this.state.entityName, res, additional)
      .then(() => {
        this.closeSeatSelectorModal();
        globalProvider.showAlert({
          message: "Item agregado a tu carrito!",
          type: "info",
          alertType: "popup",
        });
      })
      .catch((error) =>
        globalProvider.showAlert({
          title: "Error...",
          message: error,
          type: "error",
        })
      );
  };

  confirmDelete = () => {
    globalProvider.showAlert({
      title: "Eliminar Recurso",
      message: "Seguro que quiere eliminar este recurso?",
      type: "info",
      okCB: () => {
        ResourceService.remove(this.state.resource.Entidades_id, this.state.resource.id)
          .then((data) => {
            console.log(data);
            globalProvider.showAlert({
              alertType: "popup",
              type: "info",
              message:
                config.getPlaceholders.resource.singular.extendedLabel + " " + config.getPlaceholders.resource.singular.deleted + " correctamente!",
            });
            this.props.history.push("/home");
          })
          .catch((error) => {
            console.error(error);
            globalProvider.showAlert({
              alertType: "popup",
              type: "error",
              message:
                "No pudimos eliminar " + config.getPlaceholders.resource.singular.the + " " + config.getPlaceholders.resource.singular.label + "...",
            });
          });
      },
    });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //flexDirection: 'column',
          paddingTop: 10,
          //width: "100%",
          //height: "100%",
        }}
      >
        {this.state.loadingResource ? (
          <Spinner
            style={{
              marginTop: 50,
            }}
          />
        ) : (
          this.state.resource && (
            <>
              <div
                style={{
                  ...commonCardXL,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {this.state.imgStrings.length > 0 && (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      //alignItems: "center",
                      padding: 0,
                      overflow: "hidden",
                      borderRadius: 10,
                      marginBottom: 15,
                      minWidth: 100,
                    }}
                  >
                    {/**/}
                    <ImageCarousel images={this.state.imgStrings} width={"100%"} height={"100%"} />
                  </div>
                )}
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      paddingInline: 20,
                    }}
                  >
                    <h1>{this.state.resource.nombre}</h1>
                    <h4
                      style={{
                        color: colors.darkGray,
                      }}
                    >
                      de {this.state.entityName}
                    </h4>
                    {!this.state.resource.habilitado && (
                      <h4
                        style={{
                          color: colors.dangerDark,
                        }}
                      >
                        DESHABILITADO
                      </h4>
                    )}
                  </div>
                  <Ticket items={this.state.resourceTicketItems} />
                  <h3
                    style={{
                      marginTop: 10,
                      marginBottom: 0,
                      paddingInline: 20,
                    }}
                  >
                    Horarios Disponibles
                  </h3>
                  {this.state.loadingSchedule ? (
                    <Spinner />
                  ) : (
                    <Schedule fetchSchedule={this.fetchSchedule} isLoading={globalProvider.getIsLoading} editable={false} />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.authorized ? (
                      <>
                        <Button
                          style={{
                            width: "85%",
                          }}
                          onClick={this.goToEditInfo}
                        >
                          Actualizar
                        </Button>
                        <Button
                          variant="danger"
                          style={{
                            width: "85%",
                          }}
                          onClick={this.confirmDelete}
                        >
                          Eliminar
                        </Button>
                      </>
                    ) : (
                      !userProvider.getIsLoggued && (
                        <>
                          <SeatSelectorModal
                            resource={this.state.resource}
                            show={this.state.showSeatSelectorModal}
                            close={this.closeSeatSelectorModal}
                            selectItem={this.addItemToCart}
                          />
                          <Button
                            style={{
                              width: "85%",
                            }}
                            onClick={this.addToReservation}
                            variant="success"
                            disabled={!this.state.resource.habilitado}
                          >
                            {this.state.resource.habilitado
                              ? "Agregar a mi" + config.getPlaceholders.reservation.singular.label
                              : config.getPlaceholders.resource.singular.label + " deshabilitado"}
                          </Button>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>
    );
  }
}

export default withRouter(ResourceScreen);
