import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Schedule } from "../../components";
import { colors } from "../../config";
import { globalProvider, userProvider } from "../../providers";
import { ResourceService } from "../../services";

export interface Props extends RouteComponentProps {}
interface State {
  authorized: boolean;
}

class EditResourceScheduleScreen extends React.Component<Props, State> {
  resourceId: any;

  constructor(props: Props) {
    super(props);
    this.resourceId = this.props.location.pathname.split("/")[2];
    this.state = {
      authorized: false,
    };
  }

  fetchSchedule = () => {
    return new Promise((resolve, reject) => {
      ResourceService.getSchedule(this.resourceId)
        .then((data = []) => {
          if (data.length > 0) {
            let schedule = data[0];
            resolve(schedule);
            this.setState({
              authorized: userProvider.getEntityId == schedule.Entidades_id,
            });
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  buildInitialSchedule = () => {
    return Promise.resolve({
      lunes: null,
      martes: null,
      miercoles: null,
      jueves: null,
      viernes: null,
      sabado: null,
      domingo: null,
    });
  };

  updateResourceSchedule = async (schedule: any) => {
    globalProvider.setIsLoading = true;
    await ResourceService.putSchedule(this.resourceId, schedule.id, schedule)
      .then((data) => {
        globalProvider.showAlert({
          title: "Horario Actualizado!",
          message: "El horario se actualizó correctamente.",
          type: "info",
          okCB: () => {
            this.props.history.push("/home");
          },
        });
      })
      .catch((error) => {
        globalProvider.showAlert({
          title: "Hubo un error...",
          message: "No pudimos actualizar el horario, intenta nuevamente...",
          type: "error",
        });
      })
      .finally(() => {
        globalProvider.setIsLoading = false;
      });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 3,
        }}
      >
        <h3 style={{ margin: 20 }}>Horarios Disponibles</h3>
        <Schedule
          submitSchedule={this.updateResourceSchedule}
          fetchSchedule={this.fetchSchedule}
          editable={this.state.authorized}
          isLoading={globalProvider.getIsLoading}
        />
      </div>
    );
  }
}

export default withRouter(EditResourceScheduleScreen);
