import React from "react";
import { Button, Card } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { Search } from "../../components";
import { addBorders, addShadow, colors, config } from "../../config";
import { globalProvider } from "../../providers";
import { EntityService, UtilsService } from "../../services";
import { EntityCard } from "../Entity/EntityCard";

export interface Props extends RouteComponentProps {}
interface State {
  entities: any[]; //Create interface for Entity!!!
  searchEntity?: string;
}
class HomeScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entities: [],
      searchEntity: UtilsService.parseURLSearchParams(props.location.search)
        .query,
    };
  }

  componentDidMount() {
    globalProvider.setIsLoading = true;
    EntityService.get()
      .then((data) => {
        //console.log(data);
        this.setState({ entities: data });
        let ents = data.map((entity: any) => {
          return { id: entity.id, name: entity.nombre };
        });
        globalProvider.setEntityNames = ents;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        globalProvider.setIsLoading = false;
      });
  }

  goToEntity = (entity: any) => {
    globalProvider.setIsLoading = true;
    setTimeout(() => {
      this.props.history.push("/entity/" + entity.id);
    }, 500);
  };

  clearSearch = () => {};

  searchEntity = () => {};

  render() {
    return (
      <div
        style={{
          height: "100%",
          padding: 10,
        }}
      >
        <h1>{config.getPlaceholders.entity.plural.extendedLabel}</h1>

        <Search
          placeHolder={
            "Buscar " +config.getPlaceholders.entity.singular.a + " "+ config.getPlaceholders.entity.singular.label + "..."
          }
          searchValue={this.state.searchEntity}
          onType={(value: any) => {
            this.setState({
              searchEntity: value,
            });
          }}
          search={this.searchEntity}
          clearSearch={this.clearSearch}
          style={{
            maxWidth: 500,
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {this.state.entities.map((ent) => {
            return (
              <EntityCard
                action={this.goToEntity}
                actionTitle={"Más Info"}
                entity={ent}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(HomeScreen);
