export const addBorders: any = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "black",
};

export const addShadowNative: any = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.12,
  shadowRadius: 10,
  elevation: 1,
};

export const addShadow: any = {
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
};

export const addLightShadow: any = {
  boxShadow: "0 1px 5px rgb(0 0 0 / 0.1)",
};

export const addShadowTop: any = {
  boxShadow: "0 -3px 10px rgb(0 0 0 / 0.1)",
};

export const addLightShadowTop: any = {
  boxShadow: "0 -1px 5px rgb(0 0 0 / 0.1)",
};

export const colors: any = {
  primary: "#0092BF",
  darkerPrimary: "#007ba0",
  secondary: "#FFC50B",
  success: "#10dc60",
  darkSuccess: "#29CF00",
  danger: "#f04141",
  dangerLight: "#ec6666",
  dangerDark: "#a12c2c",
  dangerDarker: "#702f2f",
  gray: "#ddd",
  lightGray: "#eee",
  darkGray: "#aaa",
  darkerGray: "#777",
  carbon: "#333",
  info: "#333",
  clear: "#dfdfdf",
  clearer: "#efefef",
  info_light: "rgba(255, 255, 255, 0.5)",
  google: "#d13d35",
  facebook: "#3b5998",
  apple: "#000000",
  transparentLight: "rgba(255, 255, 255, 0.2)",
  backgroundColor: "#f8f8f8",
  backgroundColorDark: "#f5f5f5",
  warning: "#FFEC00",
};

export const styleConstants = {
  tabHeight: 45,
  containerMaxWidth: 700,
};

export const commonCard = {
  ...addShadow,
  padding: 10,
  borderRadius: 10,
  margin: 10,
  backgroundColor: 'white',
  maxWidth: styleConstants.containerMaxWidth,
  height: "fit-content",
};

export const commonCardSM = {
  ...commonCard,
  width: "fit-content",
  minWidth:'10%'
};
export const commonCardM = {
  ...commonCard,
  minWidth: "50%",
};

export const commonCardXL = {
  ...commonCard,
  minWidth: '80%',
  maxHeight: 'fit-content'
}
