import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { Spinner } from "../../components";
import { addBorders } from "../../config";
import { loaderProvider, userProvider } from "../../providers";
export interface Props extends RouteComponentProps {}

export const LoaderScreen: React.FunctionComponent<Props> = (props: Props) => {
  loaderProvider.init(window.location.href, props.location);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
};

export default withRouter(observer(LoaderScreen));
