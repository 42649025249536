export const UtilsService = (() => {
  const capitalizeFirstLetter = (chars: string = "error") => {
    let firstLetter = chars.slice(0, 1).toUpperCase();
    return firstLetter + chars.slice(1).toLowerCase();
  };

  const parseURLSearchParams = (search: string) => {
    search = search.slice(1, search.length); //Remove ? character
    let params: any = {};
    search.split("&").forEach((pairs: any) => {
      let pair = pairs.split("=");
      params[pair[0]] = pair[1];
    });
    return params;
  };

  const asyncForEach = async (array: any[], callback: any) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const parseDate = (date: Date, full: boolean = false) => {
    let d =
      date.getFullYear() +
      "-" +
      checkIfZeroNeeded(date.getMonth() + 1) +
      "-" +
      checkIfZeroNeeded(date.getDate());

    if (full) {
      d +=
        " " +
        checkIfZeroNeeded(date.getHours()) +
        ":" +
        checkIfZeroNeeded(date.getMinutes()) +
        ":" +
        checkIfZeroNeeded(date.getSeconds());
    }

    return d;
  };

  const parseStringTime = (time: string, language: "ES" = "ES") => {
    if (!isTimeFormat(time)) {
      return null;
    }

    let hours: string = time[0] + time[1];
    let minutes: string = time[3] + time[4];

    let hoursInt: number = parseInt(hours);
    let minutesInt: number = parseInt(minutes);

    let hoursString: string = hours + "hs";
    let minutesString: string = minutes + "min";
    let stringTime = "";
    if (language == "ES") {
      if (hoursInt > 0) {
        if (hoursInt < 10) hoursString = hoursString.slice(1);
        stringTime += hoursString;
        if (minutesInt > 0) {
          if (minutesInt < 10) minutesString = minutesString.slice(1);
          stringTime += " y " + minutesString;
        }
        return stringTime;
      } else {
        return minutesString;
      }
    }
  };

  const checkIfTimeRangeFits = (
    timeA: string,
    timeB: string,
    timeC: string
  ) => {
    let a: any = new Date("1970-01-01 " + timeA);
    let b: any = new Date("1970-01-01 " + timeB);
    let c: any = new Date("1970-01-01 " + timeC);

    if (a > b) {
      let aux = new Date(a);
      a = new Date(b);
      b = new Date(aux);
    }
    let d = new Date(b - a);

    return d <= c;
  };

  const parseMilitaryStringTime = (
    timeKey: string,
    parsedOffsetTime?: string
  ) => {
    //case of 90000 : 9am
    timeKey = timeKey.length == 5 ? "0" + timeKey : timeKey;
    timeKey =
      timeKey[0] +
      timeKey[1] +
      ":" +
      timeKey[2] +
      timeKey[3] +
      ":" +
      timeKey[4] +
      timeKey[5];

    if (parsedOffsetTime) {
      let x = new Date("1970-01-01  " + timeKey);

      x.setHours(
        x.getHours() + parseInt(parsedOffsetTime[0] + parsedOffsetTime[1]),
        x.getMinutes() + parseInt(parsedOffsetTime[3] + parsedOffsetTime[4]),
        x.getSeconds() + parseInt(parsedOffsetTime[6] + parsedOffsetTime[7])
      );

      timeKey =
        checkIfZeroNeeded(x.getHours()) +
        ":" +
        checkIfZeroNeeded(x.getMinutes()) +
        ":" +
        checkIfZeroNeeded(x.getSeconds());
    }

    return timeKey;
  };

  const checkIfZeroNeeded = (x: number) => {
    return x < 10 ? "0" + x : x.toString();
  };

  const isTimeFormat = (value: string) => {
    return !(
      !value ||
      value.length != 8 ||
      value[2] != ":" ||
      value[5] != ":" ||
      isNaN(parseInt(value[0])) ||
      isNaN(parseInt(value[1])) ||
      isNaN(parseInt(value[3])) ||
      isNaN(parseInt(value[4])) ||
      isNaN(parseInt(value[6])) ||
      isNaN(parseInt(value[7]))
    );
  };

  const isTimeBiggerOrEqual = (a: string, b: string) => {
    let at = new Date("1970-01-01 " + a);
    let bt = new Date("1970-01-01 " + b);
    return at >= bt;
  };

  const daysOfWeekToNumber: any = {
    domingo: 0,
    lunes: 1,
    martes: 2,
    miercoles: 3,
    jueves: 4,
    viernes: 5,
    sabado: 6,
  };

  const days = [
    "domingo",
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
  ];

  const isDayES = (d: string) => {
    return (
      d == "lunes" ||
      d == "martes" ||
      d == "miercoles" ||
      d == "jueves" ||
      d == "viernes" ||
      d == "sabado" ||
      d == "domingo"
    );
  };

  const isDayENG = (d: string) => {
    return (
      d == "monday" ||
      d == "tuesday" ||
      d == "wednesday" ||
      d == "thursday" ||
      d == "friday" ||
      d == "saturday" ||
      d == "sunday"
    );
  };

  return {
    capitalizeFirstLetter: capitalizeFirstLetter,
    parseURLSearchParams: parseURLSearchParams,
    asyncForEach: asyncForEach,
    parseDate: parseDate,
    parseMilitaryStringTime: parseMilitaryStringTime,
    parseStringTime: parseStringTime,
    checkIfZeroNeeded: checkIfZeroNeeded,
    checkIfTimeRangeFits: checkIfTimeRangeFits,
    isTimeFormat: isTimeFormat,
    daysOfWeekToNumber: daysOfWeekToNumber,
    days: days,
    isTimeBiggerOrEqual: isTimeBiggerOrEqual,
    isDayENG: isDayENG,
    isDayES: isDayES,
  };
})();
