import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Modal, Spinner } from "react-bootstrap";
import { MediaUpload } from "./MediaUpload";
import { Image } from "../../interfaces/Image.interface";
import { Icon } from "..";
import { ImageItem } from "./ImageItem";
import { globalProvider } from "../../providers";

export interface Props {
  show: boolean;
  close: () => void;
  title?: string;
  uploadedImages: Image[];
  removeUploadedImages: (imageIds: number[]) => Promise<any>;
  uploadNewImages: (images: any[]) => Promise<any>;
}

export const MediaUploadModal: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [newImages, setNewImages] = useState<any[]>([]);
  const [newImagePreviews, setNewImagePreviews] = useState<any[]>([]);
  const [currentImages, setCurrentImages] = useState<Image[]>([]);
  const [imagesToRemove, setImagesToRemove] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<number>(0);

  useEffect(() => {
    if (props.show) {
      setNewImages([]);
      setNewImagePreviews([]);
      setImagesToRemove([]);
      setCurrentImages(props.uploadedImages);
    }
  }, [props.show]);

  const removeNewImage = (index: number) => {
    let images = newImages;
    let imagePreviews = newImagePreviews;
    images.splice(index, 1);
    imagePreviews.splice(index, 1);
    setNewImages([...images]);
    setNewImagePreviews([...imagePreviews]);
  };

  const removeUploadedImage = (id: number) => {
    setImagesToRemove([...imagesToRemove, id]);
    setCurrentImages([...currentImages.filter((img) => img.id != id)]);
  };

  const confirm = () => {
    if (imagesToRemove.length > 0) {
      setIsLoading((prevState) => prevState + 1);
      props
        .removeUploadedImages(imagesToRemove)
        .then((data) => {
          globalProvider.showAlert({
            alertType: "popup",
            message: "Imagenes removidas correctamente!",
            type: "info",
          });
          props.close();
          setIsLoading((prevState) => prevState - 1);
        })
        .catch((error) => {
          globalProvider.showAlert({
            alertType: "popup",
            message: "No pudimos remover las imagenes...",
            type: "error",
          });
          setIsLoading((prevState) => prevState - 1);
        });
    }
    if (newImages.length > 0) {
      setIsLoading((prevState) => prevState + 1);
      props
        .uploadNewImages(newImages)
        .then((data) => {
          globalProvider.showAlert({
            alertType: "popup",
            message: "Imagenes nuevas cargadas correctamente!",
            type: "info",
          });
          props.close();
          setIsLoading((prevState) => prevState - 1);
        })
        .catch((error) => {
          globalProvider.showAlert({
            alertType: "popup",
            message: "No pudimos cargar las imagenes nuevas...",
            type: "error",
          });
          setIsLoading((prevState) => prevState - 1);
        });
    }
  };

  return (
    <Modal
      size={"lg"}
      fullscreen={"sm-down"}
      show={props.show}
      onHide={() => {
        props.close();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      {isLoading <= 0 ? (
        <>
          <div>
            <h5
              style={{
                marginBlock: 15,
              }}
            >
              Imagenes ya cargadas
            </h5>
            <div
              style={{
                display: "flex",
                overflow: "scroll",
              }}
            >
              {currentImages.map((img) => {
                return (
                  <ImageItem
                    id={img.id}
                    image={img.image_path}
                    clear={removeUploadedImage}
                  />
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h5
              style={{
                marginBlock: 15,
              }}
            >
              Cargar nuevas imagenes
            </h5>
            <MediaUpload
              setFile={(file) => {
                setNewImagePreviews([
                  ...newImagePreviews,
                  URL.createObjectURL(file),
                ]);
                setNewImages([...newImages, file]);
              }}
            />
          </div>
          {newImages.length > 0 && (
            <div
              style={{
                display: "flex",
                overflow: "scroll",
              }}
            >
              {newImagePreviews.map((file, index) => {
                return (
                  <ImageItem id={index} image={file} clear={removeNewImage} />
                );
              })}
            </div>
          )}

          <Button variant="success" onClick={confirm}>
            Confirmar
          </Button>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      )}
    </Modal>
  );
};
