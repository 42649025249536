import { updateStatement } from "typescript";
import { FetchService } from ".";
import {
  AdditionalsXReservation,
  Reservation,
  ResourcesXReservation,
} from "../interfaces";

export const ReservationService = (() => {
  const getByUID = (uid: string) => {
    return FetchService.callApi({
      endpoint: "/reservations/" + uid,
      method: "GET",
    });
  };

  const getResourcesByUID = (uid: string) => {
    return FetchService.callApi({
      endpoint: "/reservations/" + uid + "/resources",
      method: "GET",
    });
  };
  const getAdditionalsByUID = (uid: string) => {
    return FetchService.callApi({
      endpoint: "/reservations/" + uid + "/additionals",
      method: "GET",
    });
  };

  const getByEntity = (entityId: number) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/reservations",
      method: "GET",
    });
  };

  const post = (
    entityId: number,
    data: {
      Reservaciones: Reservation;
      Recursos_Por_Reservaciones: ResourcesXReservation[];
      Adicionales_Por_Reservaciones?: AdditionalsXReservation[];
    }
  ) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/reservations/",
      method: "POST",
      data: data,
    });
  };

  const updateState = (
    entityId: number,
    reservationId: number,
    state: "pending" | "confirm" | "done" | "cancel" | "deny"
  ) => {
    return FetchService.callApi({
      endpoint:
        "/organization/" +
        entityId +
        "/reservations/" +
        reservationId +
        "/" +
        state,
      method: "PATCH",
    });
  };

  const confirm = (entityId: number, reservationId: number) => {
    return updateState(entityId, reservationId, "confirm");
  };

  const cancel = (entityId: number, reservationId: number) => {
    return updateState(entityId, reservationId, "cancel");
  };

  const deny = (entityId: number, reservationId: number) => {
    return updateState(entityId, reservationId, "deny");
  };

  const finish = (entityId: number, reservationId: number) => {
    return updateState(entityId, reservationId, "done");
  };

  const toPending = (entityId: number, reservationId: number) => {
    return updateState(entityId, reservationId, "pending");
  };

  return {
    getByUID: getByUID,
    getResourcesByUID: getResourcesByUID,
    getAdditionalsByUID: getAdditionalsByUID,
    getByEntity: getByEntity,
    post: post,
    updateState: updateState,
    confirm: confirm,
    cancel: cancel,
    deny: deny,
    finish: finish,
    toPending: toPending,
  };
})();
