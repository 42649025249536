import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, ResourceForm, Schedule } from "../../components";
import { addBorders, addShadow, colors, config } from "../../config";
import { globalProvider, userProvider } from "../../providers";
import { ResourceService, UtilsService } from "../../services";

export interface Props extends RouteComponentProps {}

interface State {
  postError: boolean;
  resource: any;
}
class NewResourceScreen extends React.Component<Props, State> {
  flexDirection: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      postError: false,
      resource: {},
    };

    this.flexDirection = window.innerWidth < 600 ? "column" : "row";
  }

  showErrorPopup = () => {
    globalProvider.showAlert({
      message:
        "No pudimos crear " +
        config.getPlaceholders.resource.singular.the +
        " " +
        config.getPlaceholders.resource.singular.label +
        "...",
      type: "error",
      alertType: "popup",
    });
  };

  postResource = (r: any) => {
    let entityId: any = userProvider.getEntityId;
    ResourceService.post(entityId, {
      nombre: r.Nombre,
      precio: r.Precio,
      precio_promocional: r["Precio Promocional"],
      personas_habilitadas: r["Personas Habilitadas"],
      cantidad_total: r.Cantidad,
      descripcion: r.Descripcion,
      habilitado: r.Habilitado,
      max_turnos_reserva:
        r[
          "Maximo # de turnos por " +
            config.getPlaceholders.reservation.singular.label
        ],
      tiempo_turno: r["Tiempo p/ turno"],
    })
      .then((data) => {
        if (data.id) {
          let resId = data.id;
          ResourceService.postSchedule(resId, {
            lunes: null,
            martes: null,
            miercoles: null,
            jueves: null,
            viernes: null,
            sabado: null,
            domingo: null,
            Entidades_id: userProvider.getEntityId,
          })
            .then((data) => {
              globalProvider.showAlert({
                title:
                  config.getPlaceholders.resource.singular.extendedLabel +
                  " " +
                  config.getPlaceholders.resource.singular.created +
                  "!",
                message:
                  "Tu " +
                  config.getPlaceholders.resource.singular.label +
                  " fue " +
                  config.getPlaceholders.resource.singular.created +
                  " con exito. Falta definir los horarios en que opera, lo podes hacer ahora o mas tarde al editar tu " +
                  config.getPlaceholders.resource.singular.label +
                  ".",
                type: "info",
                buttons: [
                  {
                    title: "Definir Horarios",
                    action: () => {
                      globalProvider.closeAlertModal();
                      this.props.history.push(
                        "/resource/" + resId + "/schedule/edit"
                      );
                    },
                    type: "info",
                  },
                  {
                    title: "Más tarde",
                    action: () => {
                      globalProvider.closeAlertModal();
                      this.props.history.push("/home");
                    },
                    type: "info",
                  },
                ],
              });
            })
            .catch((error) => {
              console.error("Horario Error: ", error);
              //Delete resource!
              this.showErrorPopup();
            });
        } else {
          this.showErrorPopup();
          //ToDo Show Error!
        }
      })
      .catch((error) => {
        console.error(error);
        this.showErrorPopup();
      });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: 10,
          //width: 'fit-content'
        }}
      >
        <div
          style={{
            minWidth: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>
            Crear {config.getPlaceholders.resource.singular.extendedLabel}
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              ...addShadow,
              borderRadius: 10,
              height: "fit-content",
              padding: 15,
            }}
          >
            <ResourceForm
              submit={this.postResource}
              error={this.state.postError}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(NewResourceScreen));
