import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { addBorders, addShadow, addShadowTop, colors, config } from "../../config";
import { UtilsService } from "../../services";
import { ResourceService } from "../../services/Resource.service";
import {
  AdditionalsXReservation,
  AdditionalsXReservationExt,
  ResourcesXReservation,
  ResourcesXReservationExt,
} from "../../interfaces";
import { Spinner } from "../";

export interface Props {
  show: boolean;
  close: () => void;
  selectItem: (
    res: ResourcesXReservationExt,
    additional?: AdditionalsXReservationExt
  ) => void;
  resource: any;
}

export const SeatSelectorModal: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [disabledDays, setDisabledDays] = useState<number[]>([]);
  const [loadingDisabledDays, setLoadingDisabledDays] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<any[]>([]);
  const [availableSeats, setAvailableSeats] = useState<any>({});
  const [loadingAvailableSeats, setLoadingAvailableSeats] =
    useState<boolean>(false);
  const [maxAvailable, setMaxAvailable] = useState<number>(0);
  const [maxAvailableForSelectedSeats, setMaxAvailableForSelectedSeats] =
    useState<number>(1000000);
  const [amountSelected, setAmountSelected] = useState<number>(1);
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [seatTime, setSeatTime] = useState<string>("00:00:00");
  const [maxSeats, setMaxSeats] = useState<number>(0);
  const [maxSeatsSelectedError, setMaxSeatsSelectedError] =
    useState<boolean>(false);

  const clearSelection = () => {
    setAvailableSeats({});
    setSelectedDate(undefined);
    setSelectedTime([]);
    setAmountSelected(1);
  };

  const selectSeat = () => {
    let d: any = selectedDate;
    let price = currentPrice;
    let res: ResourcesXReservationExt = {
      Recursos_id: props.resource.id,
      cantidad_reservada: amountSelected,
      fecha_recurso_reservado: UtilsService.parseDate(d),
      hora_inicio_recurso_reservado: UtilsService.parseMilitaryStringTime(
        selectedTime[0][1]
      ),
      hora_final_recurso_reservado: UtilsService.parseMilitaryStringTime(
        selectedTime[selectedTime.length - 1][1],
        seatTime
      ),
      precio_total_reserva: price,
      nombre: props.resource.nombre,
      personas_habilitadas: props.resource.personas_habilitadas,
      tiempo_turno: props.resource.tiempo_turno,
    };

    props.selectItem(res);
  };

  useEffect(() => {
    if (props.show) {
      setLoadingDisabledDays(true);
      ResourceService.getSchedule(props.resource.id)
        .then((data: any[] = []) => {
          if (data.length > 0) {
            let dds: number[] = [];
            for (let day in data[0]) {
              if (
                data[0][day] == null &&
                UtilsService.daysOfWeekToNumber[day] != undefined
              ) {
                dds.push(UtilsService.daysOfWeekToNumber[day]);
              }
            }
            setDisabledDays([...dds]);
          }
        })
        .catch((error) => {
          setDisabledDays([]);
        })
        .finally(() => {
          setLoadingDisabledDays(false);
        });

      setOriginalPrice(
        props.resource.precio_promocional
          ? props.resource.precio_promocional
          : props.resource.precio
      );

      setCurrentPrice(originalPrice);
    } else {
      setTimeout(() => {
        setAvailableSeats({});
        setSelectedDate(undefined);
        setSelectedTime([]);
        setCurrentPrice(0);
        setOriginalPrice(0);
        setAmountSelected(1);
      }, 500);
    }
  }, [props.show]);

  const calculateCurrentPrice = (
    amountOfShifts: number,
    amountSelected: number
  ) => {
    setCurrentPrice(originalPrice * amountSelected * amountOfShifts);
  };

  const checkAvailableSeats = (date: Date) => {
    setLoadingAvailableSeats(true);
    setSelectedDate(date);
    let stringDate =
      date.getFullYear() +
      "-" +
      UtilsService.checkIfZeroNeeded(date.getMonth() + 1) +
      "-" +
      UtilsService.checkIfZeroNeeded(date.getDate());

    ResourceService.getAvailableSeats(
      props.resource.Entidades_id,
      props.resource.id,
      stringDate
    )
      .then((data) => {
        setAvailableSeats(data.horarios);
        setMaxAvailable(data.cantidad_total);
        setMaxAvailableForSelectedSeats(data.cantidad_total);
        setMaxSeats(data.max_turnos_reserva);
        setSeatTime(data.tiempo_turno);
      })
      .catch((error) => {
        setAvailableSeats({});
        setMaxAvailable(0);
        setMaxAvailableForSelectedSeats(1000000);
        setMaxSeats(0);
        setSeatTime("00:00:00");
      })
      .finally(() => {
        setLoadingAvailableSeats(false);
      });
  };

  const removeSelectedTime = (key: string) => {
    setMaxSeatsSelectedError(false);
    let time: any = [];
    let available = 1000000;
    for (let i = 0; i < selectedTime.length; i++) {
      if (selectedTime[i][1] != key) {
        time.push(selectedTime[i]);
        if (availableSeats[selectedTime[i][1]] < available) {
          available = availableSeats[selectedTime[i][1]];
        }
      } else {
        break;
      }
    }

    setSelectedTime(time);
    setMaxAvailableForSelectedSeats(available);
    calculateCurrentPrice(time.length, amountSelected);
  };

  const canBeSelected = (index: number) => {
    if (selectedTime.length > 0) {
      let [firstSelectedTimeIdx, firstSelectedTime] = selectedTime[0];
      let [lastSelectedTimeIdx, lastSelectedTime] =
        selectedTime[selectedTime.length - 1];
      return (
        index == firstSelectedTimeIdx - 1 || index == lastSelectedTimeIdx + 1
      );
    }
    return true;
  };

  const addSelectedTime = (index: number, key: string, alreadyUsed: number) => {
    if (selectedTime.length >= maxSeats) {
      setMaxSeatsSelectedError(true);
      return;
    }
    let i = 0;
    let ss = [];
    if (canBeSelected(index)) {
      while (i < selectedTime.length && index > selectedTime[i][0]) {
        i++;
      }
      ss = selectedTime;
      ss.splice(i, 0, [index, key]);
    } else {
      ss.push([index, key]);
    }

    setSelectedTime([...ss]);
    let available = maxAvailable - alreadyUsed;
    let selectedAux = amountSelected;
    if (available < maxAvailableForSelectedSeats || ss.length == 1) {
      setMaxAvailableForSelectedSeats(available);
      if (available < amountSelected) {
        setAmountSelected(available);
        selectedAux = available;
      }
    }
    calculateCurrentPrice(ss.length, selectedAux);
  };

  return (
    <Modal
      show={props.show}
      fullscreen={"sm-down"}
      onHide={() => {
        props.close();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Agregar {props.resource?.nombre} a mi {config.getPlaceholders.reservation.singular.label}</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          paddingBottom: 180,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loadingDisabledDays ? (
              <Spinner animation={"border"} size={"sm"} />
            ) : (
              <DayPicker
                disabledDays={[
                  { daysOfWeek: disabledDays },
                  { before: new Date() },
                ]}
                onDayClick={(date: Date, modifiers: any) => {
                  if (!modifiers.disabled) {
                    setSelectedTime([]);
                    setAmountSelected(1);
                    checkAvailableSeats(date);
                  }
                }}
                selectedDays={[selectedDate]}
              />
            )}
          </div>

          {selectedDate &&
            (loadingAvailableSeats ? (
              <Spinner animation={"border"} size={"sm"} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    alignSelf: "start",
                    textAlign: "start",
                    fontSize: 18,
                    marginBottom: 0,
                  }}
                >
                  Seleccione un horario o rango de horarios
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: 15,
                  }}
                >
                  Para rangos de horarios, solo se pueden seleccionar turnos
                  contiguos
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 15,
                    width: "75%",
                    overflow: "hidden",
                  }}
                >
                  {Object.keys(availableSeats).map((key, index) => {
                    let backColor = "white",
                      color = colors.carbon,
                      borderColor = colors.primary;
                    let disabled = false;
                    let selected = false;
                    if (availableSeats[key] >= maxAvailable) {
                      backColor = colors.gray;
                      color = colors.carbon;
                      borderColor = colors.gray;
                      disabled = true;
                    } else {
                      if (selectedTime.find((t) => t[1] == key)) {
                        backColor = colors.primary;
                        color = "white";
                        selected = true;
                      }
                    }
                    return (
                      <Button
                        disabled={disabled}
                        key={index}
                        style={{
                          //marginBlock: 5,
                          borderRadius: 0,
                          borderBlockWidth: 0,
                          width: "100%",
                          fontSize: 15,
                          backgroundColor: backColor,
                          color: color,
                          borderColor: borderColor,
                        }}
                        onClick={() => {
                          if (selected) {
                            removeSelectedTime(key);
                          } else {
                            addSelectedTime(index, key, availableSeats[key]);
                          }
                        }}
                      >
                        {UtilsService.parseMilitaryStringTime(key)}
                      </Button>
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      </Modal.Body>
      {selectedTime.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            ...addShadowTop,
            borderRadius: 10,
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          {maxSeatsSelectedError && (
            <p
              style={{
                color: colors.danger,
                margin: 0,
              }}
            >
              No se pueden seleccionar más de {maxSeats} turnos.
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
            }}
          >
            <p
              style={{
                marginTop: 15,
                fontSize: 18,
              }}
            >
              {UtilsService.parseMilitaryStringTime(selectedTime[0][1])}
              {" - "}
              {UtilsService.parseMilitaryStringTime(
                selectedTime[selectedTime.length - 1][1],
                seatTime
              )}
            </p>
            <Button
              style={{
                borderRadius: 50,
                marginInline: 10,
                //padding:5,
                fontWeight: "bolder",
              }}
              onClick={() => {
                if (amountSelected > 1) {
                  setAmountSelected(amountSelected - 1);
                  calculateCurrentPrice(
                    selectedTime.length,
                    amountSelected - 1
                  );
                }
              }}
            >
              -
            </Button>
            <p
              style={{
                fontSize: 20,
                marginTop: 10,
              }}
            >
              {amountSelected}
            </p>
            <Button
              style={{
                borderRadius: 50,
                marginInline: 10,
                fontWeight: "bolder",
              }}
              onClick={() => {
                if (amountSelected < maxAvailableForSelectedSeats) {
                  setAmountSelected(amountSelected + 1);
                  calculateCurrentPrice(
                    selectedTime.length,
                    amountSelected + 1
                  );
                }
              }}
            >
              +
            </Button>
          </div>

          <p
            style={{
              fontSize: 25,
            }}
          >
            ${currentPrice}
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              style={{
                fontSize: 12,
                width: "40%",
                borderRadius: 10,
              }}
              variant="outline-danger"
              onClick={clearSelection}
            >
              Limpiar Selección
            </Button>
            <Button
              style={{
                fontSize: 12,
                // paddingInline: 5,
                width: "40%",
                borderRadius: 10,
              }}
              variant="outline-success"
              onClick={selectSeat}
            >
              Confirmar
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
