import { FetchService } from "./Fetch.service";
import { sha256 } from "js-sha256";

export const LoginService = (() => {
  const login = (email: string, password: string) => {
    return FetchService.callApi({
      endpoint: "/auth/login",
      method: "POST",
      data: {
        email: email,
        password: sha256(password),
      },
    });
  };

  const register = (email: string, password: string, entityId: number) => {
    return FetchService.callApi({
      endpoint: "/auth/register",
      method: "POST",
      data: {
        email: email,
        password: sha256(password),
        entityId: entityId,
      },
    });
  };

  const checkTokens = (accessToken: string) => {
    return FetchService.callApi({
      endpoint: "/auth/tokens",
      method: "GET",
      auth: "Bearer " + accessToken,
    });
  };

  return {
    login: login,
    register: register,
    checkTokens: checkTokens
  };
})();
