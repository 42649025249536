import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { addShadow, config } from "../../config";
import { EntityService } from "../../services";

export interface Props {
  entity: any;
  actionTitle: string;
  action: (resource: any) => void;
}

export const EntityCard: React.FunctionComponent<Props> = (props: Props) => {
  const [defaultImage, setDefaultImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    EntityService.getImages(props.entity.id, true)
      .then((defImg) => {
        setDefaultImage(config.getAPI_URL + "/uploads/" + defImg.image_path);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Card
      key={props.entity.id}
      style={{
        width:'100%',
        maxWidth: 350,
        //width: "18rem",
        margin: 5,
        ...addShadow,
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <img
        style={{
          maxWidth: "100%",
          height: 300,
          objectFit: "contain",
        }}
        onClick={() => {
          props.action(props.entity);
        }}
        src={
          defaultImage
            ? defaultImage
            : config.getAPI_URL + "/uploads/default.png"
        }
      />

      <Card.Body
        onClick={() => {
          props.action(props.entity);
        }}
      >
        <Card.Title>{props.entity.nombre}</Card.Title>
        <Card.Text>{props.entity.direccion}</Card.Text>
        <Button
          style={{ margin: 3, borderRadius: 10, fontSize: 12 }}
          variant="outline-primary"
        >
          {props.actionTitle}
        </Button>
      </Card.Body>
    </Card>
  );
};
