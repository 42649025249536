import React, {useState} from 'react';
import {FieldProps} from './Form';
import {Error} from './Error';
import {Button} from 'react-bootstrap';
import { colors } from '../../config';

export const DropdownField: React.FunctionComponent<FieldProps> = (
  props: FieldProps,
) => {
  const valueTypes: any = props.type;
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<{
    label: any;
    value: any;
  }>({
    label: undefined,
    value: undefined,
  });
  let orderBy = '';
  if (props.value != undefined) {
    orderBy = valueTypes.find(
      (option: {label: string; value: string}) => props.value == option.value,
    ).label;
  } else if (selectedOption.label != undefined) {
    orderBy = selectedOption.label;
  }
  return (
    <Button
      onClick={() => {
        setShowOptions(!showOptions);
      }}
      style={{
        marginBottom: props.style.marginBottom ? props.style.marginBottom : 15,
      }}>
      <div
        style={{
          borderRadius: 10,
          borderBottomWidth: 1,
          borderBottomColor: colors.gray,
          paddingVertical: 5,
          paddingHorizontal: 10,
          ...props.style,
        }}>
        <p
          style={{
            fontSize: props.style.fontSize ? props.style.fontSize : 18,
            color: props.style.fontColor
              ? props.style.fontColor
              : colors.carbon,
          }}>
          {props.placeHolder} {orderBy}
        </p>

        {showOptions && (
          <div>
            {valueTypes.map(
              (option: {label: string; value: string}, index: number) => (
                <Button
                  key={index}
                  onClick={() => {
                    props.handleChange(option.value);
                    setSelectedOption({
                      label: option.label,
                      value: option.value,
                    });
                  }}
                  style={{
                    marginTop: 5,
                    marginBottom: 5,

                  }}>
                  <p
                    style={{
                      fontSize: 18,
                      color:
                        selectedOption.value == option.value
                          ? colors.primary
                          : colors.darkerGray,
                    }}>
                    {option.label}
                  </p>
                </Button>
              ),
            )}
          </div>
        )}
      </div>

      <Error
        error={props.error}
        style={props.errorStyle}
        touched={props.touched}
      />
    </Button>
  );
};
