import { makeAutoObservable } from "mobx";
import { User } from "../interfaces";
import { LoginService, StorageService } from "../services";

class UserProvider {
  isLoggued: boolean = false;
  user: User = {
    id: -1,
    email: "",
    Entidades_id: -1,
    estado: false,
    fecha_creacion: new Date(),
  };
  accessToken?: string;
  refreshToken?: string;

  constructor() {
    makeAutoObservable(this);
  }

  cleanTokens = () => {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    StorageService.cleanTokens();
  };

  cleanUser = () => {
    this.user = {
      id: -1,
      email: "",
      Entidades_id: -1,
      estado: false,
      fecha_creacion: new Date(),
      fecha_actualizacion: undefined,
    };
  };

  checkIfLoggued = () => {
    return new Promise<void>((resolve, reject) => {
      let tokens = StorageService.getTokens();

      if (!tokens.accessToken) {
        this.cleanTokens();
        this.cleanUser();
        reject();
        return;
      }

      LoginService.checkTokens(tokens.accessToken)
        .then(({ user, tokens }) => {
          resolve();
          this.assignUser(user, tokens, true);
        })
        .catch((error) => {
          console.error("User Provider | checkIfLoggued: ", error);
          reject();
        });
    });
  };

  assignUser = (
    user: User,
    tokens: { accessToken: string; refreshToken: string },
    stayLogged: boolean = true
  ) => {
    this.isLoggued = true;

    this.user = {
      id: user.id,
      email: user.email,
      Entidades_id: user.Entidades_id,
      estado: user.estado,
      fecha_creacion: new Date(user.fecha_creacion),
      fecha_actualizacion: user.fecha_actualizacion
        ? new Date(user.fecha_actualizacion)
        : undefined,
    };

    this.accessToken = tokens.accessToken;
    this.refreshToken = tokens.refreshToken;

    if (stayLogged)
      StorageService.setTokens({
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
      });
  };

  login = (email: string, password: string, stayLogged: boolean = true) => {
    console.log("Stay loggued? ", stayLogged)
    return new Promise<void>((resolve, reject) => {
      LoginService.login(email, password)
        .then(({ user, tokens }) => {
          this.assignUser(user, tokens, stayLogged);
          resolve();
        })
        .catch((error) => {
          console.error("user Provider | login: ", error);
          this.cleanUser();
          this.cleanTokens();
          reject();
        });
    });
  };

  logout = () => {
    this.isLoggued = false;
    this.cleanTokens();
    this.cleanUser();
  };

  get getIsLoggued() {
    return this.isLoggued;
  }

  set setIsLoggued(value: boolean) {
    this.isLoggued = value;
  }

  get getUser() {
    return this.user;
  }

  get getEntityId() {
    return this.user.Entidades_id > 0 ? this.user.Entidades_id : undefined;
  }

  get getAccessToken() {
    return this.accessToken;
  }
}

export const userProvider = new UserProvider();
