
const apiURL = {
  prod: "https://api.tusreservas.net/v1",
  beta: "https://api.tusreservas.net/beta",
  dev: "http://localhost:4000",
  //dev: "http://192.168.1.6:4000"
};

export type ENV_TYPE = "prod" | "beta" | "dev";

class Config {
  api: {
    url: string;
    force?: 'beta' | 'prod'  //Only relevant in dev environment
  };
  placeholders:any;

  constructor() {
    this.api = {
      url: apiURL["dev"],
      //force: 'beta',
    };
    this.placeholders = require("../placeholders.json");
  }

  set setAPI_URL(value: ENV_TYPE) {
    if (value == "dev" && this.api.force) {
      this.api.url = apiURL[this.api.force];
    } else {
      this.api.url = apiURL[value];
    }
  }

  get getAPI_URL() {
    return this.api.url;
  }

  get getPlaceholders(){
    return this.placeholders
  }
}

export const config = new Config();
