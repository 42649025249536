import * as hs from "./Home/HomeScreen";
import * as es from "./Entity/EntityScreen";
import * as cs from "./Cart/CartScreen";
import * as rl from "./Reservation/ReservationLobby";
import * as ls from "./Login/LoginScreen";
import * as ers from "./Resource/EditResourceScreen";
import * as nrs from "./Resource/NewResourceScreen";
import * as erss from "./Resource/EditResourceScheduleScreen";
import * as rs from "./Resource/ResourceScreen";
import * as lrs from "./Loader/LoaderScreen";

export const HomeScreen = hs.default;
export const EntityScreen = es.default;
export const CartScreen = cs.default;
export const ReservationLobby = rl.default;
export const LoginScreen = ls.default;
export const EditResourceScreen = ers.default;
export const NewResourceScreen = nrs.default;
export const EditResourceScheduleScreen = erss.default;
export const ResourceScreen = rs.default;
export const LoaderScreen = lrs.default;
