import React, { useEffect } from "react";
import { Alert, Fade } from "react-bootstrap";
import { colors } from "../../config";
import { AlertButton, AlertType } from "../../interfaces";

export interface Props {
  show: boolean;
  close: () => void;
  title?: string;
  message: string;
  type: AlertType;
  timeout?: number;
  onHideCB?: () => void;
}

export const AlertPopUp: React.FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    if (props.show) {
      let timeout = props.timeout ? props.timeout : 3000;
      setTimeout(() => {
        if (props.onHideCB) props.onHideCB();
        props.close();
      }, timeout);
    }
  }, [props.show]);

  return (
    <Alert
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 300,
        margin: 0,
        width: "100%",
        backgroundColor: colors.darkerGray,
        color: colors.clear,
        maxWidth: 500,
      }}
      show={props.show}
      onClose={() => {
        if (props.onHideCB) props.onHideCB();
        props.close();
      }}
      dismissible
      variant={props.type == "info" ? "dark" : "danger"}
      transition={Fade}
    >
      {props.title && <Alert.Heading>{props.title}</Alert.Heading>}
      {props.message}
    </Alert>
  );
};
