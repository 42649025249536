import { FieldProps } from "./Form";
import React from "react";
import { colors } from "../../config";
import { Error } from "./Error";
import { FloatingLabel, Form as FormBoot } from "react-bootstrap";

export const TextField: React.FunctionComponent<FieldProps> = (
  props: FieldProps
) => {
  return (
    <div
      style={{
        marginBottom: props.style.marginBottom ? props.style.marginBottom : 15,
      }}
    >
      <div
        style={{
          borderRadius: 10,
          borderBottomWidth: 1,
          borderBottomColor: colors.gray,
          paddingVertical: 5,
          paddingHorizontal: 10,
          ...props.style,
        }}
      >
        <FloatingLabel
          controlId="floatingInput"
          label={props.label}
          className="mb-3"
        >
          <FormBoot.Control
            type={
              props.type == "password"
                ? "password"
                : props.type == "number"
                ? "number"
                : "text"
            }
            placeholder={props.placeHolder}
            readOnly={!props.editable}
            style={{
              fontSize: props.style.fontSize ? props.style.fontSize : 18,
              color: props.style.fontColor
                ? props.style.fontColor
                : colors.carbon,
            }}
            onChange={props.handleChange(props.label)}
            onChangeCapture={(e: any) => {
              if (props.onChangeCallback)
                props.onChangeCallback(e.target.value);
            }}
            onBlur={props.handleBlur(props.label)}
            value={props.value}
          />
        </FloatingLabel>
        {/** 
        <TextInput
          keyboardType={props.type == 'number' ? 'numeric' : 'default'}
          secureTextEntry={props.type=='password'}
          editable={props.editable}
          style={{
            fontSize: props.style.fontSize ? props.style.fontSize : 18,
            color: props.style.fontColor
              ? props.style.fontColor
              : colors.carbon,
          }}
          placeholder={props.placeHolder}
          onChange={(e:any)=>{
            if(props.onChangeCallback) props.onChangeCallback(e.nativeEvent.text);
          }}
          onChangeText={props.handleChange(props.label)}
          onBlur={props.handleBlur(props.label)}
          value={props.value}
        />
        */}
      </div>
      <Error
        error={props.error}
        style={props.errorStyle}
        touched={props.touched}
      />
    </div>
  );
};
