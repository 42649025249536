import { makeAutoObservable } from "mobx";
import { ENV_TYPE } from "../config/config";
import { AlertButton, AlertType } from "../interfaces";
import { EntityService } from "../services";

class GlobalProvider {
  entityNames: { id: number; name: string }[] = [];

  isLoading: boolean = false;

  param: {
    for?: string;
    value?: any;
  } = {};

  error: {
    show: boolean;
    title?: string;
    message: string;
  } = {
    show: false,
    message: "",
  };

  alert: {
    alertType: "popup" | "modal";
    show: boolean;
    title?: string;
    message: string;
    type: AlertType;
    timeout?: number;
    okCB?: () => void;
    onHideCB?: () => void;
    buttons?: AlertButton[];
  } = {
    alertType: "modal",
    show: false,
    message: "",
    type: "info",
  };

  environment: ENV_TYPE = "prod";

  constructor() {
    makeAutoObservable(this);
  }

  get getIsLoading() {
    return this.isLoading;
  }

  set setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  showAlert = (data: {
    title?: string;
    message: string;
    type: AlertType;
    timeout?: number;
    okCB?: () => void;
    onHideCB?: () => void;
    buttons?: AlertButton[];
    alertType?: "modal" | "popup";
  }) => {
    this.alert = {
      alertType: data.alertType ? data.alertType : "modal",
      show: true,
      message: data.message,
      title: data.title,
      type: data.type,
      timeout: data.timeout,
      okCB: data.okCB,
      onHideCB: data.onHideCB,
      buttons: data.buttons,
    };
  };

  closeAlertModal = () => {
    this.alert = {
      alertType: "modal",
      show: false,
      message: "",
      type: "info",
      title: undefined,
      timeout: undefined,
      okCB: undefined,
      onHideCB: undefined,
    };
  };

  get shouldShowAlertPopUp() {
    return this.alert.alertType == "popup" && this.alert.show;
  }

  get shouldShowAlertModal() {
    return this.alert.alertType == "modal" && this.alert.show;
  }

  get alertMessage() {
    return this.alert.message;
  }

  get alertTitle() {
    return this.alert.title;
  }

  get alertType() {
    return this.alert.type;
  }

  get alertTimeout() {
    return this.alert.timeout;
  }

  get alertOkCB() {
    return this.alert.okCB;
  }

  get alertButtons() {
    return this.alert.buttons;
  }

  get alertOnHideCB() {
    return this.alert.onHideCB;
  }

  getParam = (from: string) => {
    let val = undefined;
    if (this.param.for == from) {
      val = this.param.value;
      this.param = {};
    }
    return val;
  };

  setParam = (param: { for: string; value: any }) => {
    this.param = param;
  };

  set setEntityNames(value: { id: number; name: string }[]) {
    this.entityNames = value;
  }

  set addEntityName(value: { id: number; name: string }) {
    if (!this.entityNames.find((entity) => entity.id == value.id)) {
      this.entityNames.push(value);
    }
  }

  get getEntityNames() {
    return this.entityNames;
  }

  getEntityName(id: number) {
    let entity = this.entityNames.find((entity) => entity.id == id);
    if (entity) return Promise.resolve(entity.name);

    return new Promise<string>((resolve, reject) => {
      EntityService.get(id, true)
        .then((data) => {
          resolve(data.nombre);
          this.addEntityName = { id, name: data.nombre };
        })
        .catch((error) => {
          reject();
        });
    });
  }

  get getEnvironment() {
    return this.environment;
  }

  set setEnvironment(value: ENV_TYPE) {
    this.environment = value;
  }
}

export const globalProvider = new GlobalProvider();
