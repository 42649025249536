import React, { useState } from "react";
import { Drawer, DrawerBodyButton, DrawerButton, DrawerHeader, Icon } from "..";

import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { addBorders, colors } from "../../config";

export interface Props {
  children: any;
  header: DrawerHeader;
  sections: DrawerBodyButton[];
  footer?: DrawerButton[];
  backgroundColor?:string
}

export const Layout: React.FunctionComponent<Props> = (props: Props) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const renderIconButton = (
    button: DrawerButton | DrawerBodyButton,
    index: number
  ) => {
    return (
      <OverlayTrigger
        key={index}
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip" {...props}>
            {button.title}
          </Tooltip>
        }
      >
        <Button
          style={{
            position: "relative",
            borderRadius: 15,
            marginBlock: 10,
            marginInline: 3,
            padding: 10,
          }}
          onClick={button.call}
          variant={button.variant ? button.variant : "outline-primary"}
        >
          {button.badge && (
            <div
              style={{
                color: button.badge.color ? button.badge.color : "white",
                backgroundColor: button.badge.backgroundColor
                  ? button.badge.backgroundColor
                  : "red",
                borderRadius: 100,
                position: "absolute",
                width: 20,
                height: 20,
                top: -10,
                right: -10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {button.badge.value}
            </div>
          )}
          <Icon name={button.icon ? button.icon : ""} size={18} />
        </Button>
      </OverlayTrigger>
    );
  };
  return (
    <div
      style={{
        display: "flex",
   //     flexDirection: window.innerWidth < 450 ? 'column' : 'row'
      }}
    >
      <Drawer
        show={showDrawer}
        close={() => {
          setShowDrawer(false);
        }}
        header={props.header}
        bodyButtons={props.sections}
        footerButtons={props.footer}
      />

      {false ? ( //window.innerWidth < 450 ? (
        <Button
          style={{
            borderWidth: 0,
            width: 'fit-content',
            position: 'sticky',
            top: 10,
            left:10,
            zIndex: 300,
            backgroundColor:"white",
            opacity: 0.9
          }}
          variant="outline-secondary"
          onClick={() => {
            setShowDrawer(true);
          }}
        >
          <Icon name="list" color={"gray"} />
        </Button>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 5,
          }}
        >
          <Button
            style={{
              borderWidth: 0,
            }}
            variant="outline-secondary"
            onClick={() => {
              setShowDrawer(true);
            }}
          >
            <Icon name="list" color={"gray"} />
          </Button>

          {props.sections.map((button, index) => {
            return renderIconButton(button, index);
          })}
          <div style={{ position: "absolute", bottom: 10 }}>
            {props.footer?.map((button, index) => {
              return renderIconButton(button, index);
            })}
          </div>
        </div>
      )}
      <div
        style={{
          flex: 30,
          //height: '100vh',
          height: window.innerHeight,
          overflow: "scroll",
          backgroundColor: props.backgroundColor
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
