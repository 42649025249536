import { getTokenSourceMapRange } from "typescript";

export const StorageService = (() => {
  const get = (key: string) => {
    return localStorage.getItem(key);
  };

  const set = (key: string, value: any) => {
    localStorage.setItem(key, value);
  };

  const getReservationUIDs = () => {
    return localStorage.getItem("reservations")?.split(",");
  };

  const setReservationUIDs = (uid: string) => {
    localStorage.setItem("reservations", uid);
  };

  const addReservationUID = (uid: string) => {
    let stored = localStorage.getItem("reservations");
    stored = stored ? stored + "," + uid : uid;
    setReservationUIDs(stored);
  };

  const setTokens = (tokens: { accessToken: string; refreshToken: string }) => {
    localStorage.setItem("at", tokens.accessToken);
    localStorage.setItem("rt", tokens.refreshToken);
  };

  const getTokens = () => {
    return {
      accessToken: localStorage.getItem("at"),
      refreshToken: localStorage.getItem("rt"),
    };
  };

  const cleanTokens = () => {
    localStorage.removeItem("at");
    localStorage.removeItem("rt");
  };

  return {
    get: get,
    set: set,
    getReservationUIDs: getReservationUIDs,
    setReservationUIDs: setReservationUIDs,
    addReservationUID: addReservationUID,
    setTokens: setTokens,
    getTokens: getTokens,
    cleanTokens: cleanTokens,
  };
})();
