import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import { observer } from "mobx-react";
import { globalProvider, loaderProvider, userProvider } from "../providers";
import { Loader as Loading } from "../components/Loader/Loader";
import { AlertModal, AlertPopUp } from "../components";
import LoaderScreen from "../screens/Loader/LoaderScreen";

export interface Props {}

const AppNavigation: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Router>
      {loaderProvider.getIsStarting ? (
        <LoaderScreen />
      ) : (
        <>
          {globalProvider.getIsLoading && <Loading />}
          <AlertModal
            show={globalProvider.shouldShowAlertModal}
            message={globalProvider.alertMessage}
            close={globalProvider.closeAlertModal}
            title={globalProvider.alertTitle}
            type={globalProvider.alertType}
            timeout={globalProvider.alertTimeout}
            okCB={globalProvider.alertOkCB}
            buttons={globalProvider.alertButtons}
            onHideCB={globalProvider.alertOnHideCB}
          />
          <AlertPopUp
            show={globalProvider.shouldShowAlertPopUp}
            message={globalProvider.alertMessage}
            close={globalProvider.closeAlertModal}
            title={globalProvider.alertTitle}
            type={globalProvider.alertType}
            timeout={globalProvider.alertTimeout}
            onHideCB={globalProvider.alertOnHideCB}

          />

          <MainNavigation />
        </>
      )}
    </Router>
  );
};

export default observer(AppNavigation);
