import { FetchService } from ".";

export const EntityService = (() => {
  const get = (id: any = "", justName: boolean = false) => {
    return FetchService.callApi({
      endpoint: "/organization/" + id,
      method: "GET",
      data: {
        justName: justName,
      },
    });
  };

  const getSchedule = (id: number) => {
    return FetchService.callApi({
      endpoint: "/organization/" + id + "/schedule",
      method: "GET",
    });
  };

  const post = () => {};

  const getImages = (id: number, justDefault: boolean = false) => {
    let lastPart = justDefault ? "/defaultImage" : "/images";

    return FetchService.callApi({
      endpoint: "/organization/" + id + lastPart,
      method: "GET",
    });
  };

  return {
    get: get,
    post: post,
    getSchedule: getSchedule,
    getImages: getImages,
  };
})();
