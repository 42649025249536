import { makeAutoObservable } from "mobx";
import { globalProvider, userProvider } from ".";
import { config } from "../config";

class LoaderProvider {
  isStarting: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  init = async (url: any, location: any) => {
    this.assignEnvironment(url);
    userProvider.checkIfLoggued().finally(()=>{
      this.isStarting = false;
    });
  };

  assignEnvironment = (url: string) => {
    console.log(url);
    if (url.startsWith("http://192") || url.startsWith("http://localhost") ) {
      globalProvider.setEnvironment = "dev";
      config.setAPI_URL = "dev";
    } else if (url.startsWith("https://beta")) {
      globalProvider.setEnvironment = "beta";
      config.setAPI_URL = "beta";
    } else {
      globalProvider.setEnvironment = "prod";
      config.setAPI_URL = "prod";
    }
  };

  set setIsStarting(value: boolean) {
    this.isStarting = value;
  }

  get getIsStarting() {
    return this.isStarting;
  }
}

export const loaderProvider = new LoaderProvider();
