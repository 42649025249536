import { FetchService } from ".";

export const ResourceService = (() => {
  const get = (resourceId: number) => {
    return FetchService.callApi({
      endpoint: "/resources/" + resourceId,
      method: "GET",
    });
  };

  const getByEntity = (entityId: number, onlyEnabled:boolean = false) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + (onlyEnabled == true ? "enabled" : ""),
      method: "GET",
    });
  };

  const put = (entityId: number, resourceId: number, resource: any) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + resourceId,
      method: "PATCH",
      data: resource,
    });
  };

  const post = (entityId: number, resource: any) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources",
      method: "POST",
      data: resource,
    });
  };

  const remove = (entityId: number, resourceId: number) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + resourceId,
      method: "DELETE",
    });
  };

  const getAvailableSeats = (entityId: number, resourceId: number, date: string) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + resourceId + "/availableSeats",
      method: "GET",
      data: {
        date: date,
      },
    });
  };

  const getSchedule = (resourceId: number) => {
    return FetchService.callApi({
      endpoint: "/resources/" + resourceId + "/schedule",
      method: "GET",
    });
  };

  const putSchedule = (resourceId: number, scheduleId: number, schedule: any) => {
    return FetchService.callApi({
      endpoint: "/resources/" + resourceId + "/schedule/" + scheduleId,
      method: "PATCH",
      data: schedule,
    });
  };

  const postSchedule = (resourceId: number, schedule: any) => {
    return FetchService.callApi({
      endpoint: "/resources/" + resourceId + "/schedule",
      method: "POST",
      data: schedule,
    });
  };

  const getImages = (entityId: number, resourceId: number, justDefault: boolean = false) => {
    let lastPart = justDefault ? "/defaultImage" : "/images";

    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + resourceId + lastPart,
      method: "GET",
    });
  };

  const postImages = (entityId: number, resourceId: number, images: any[]) => {
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + resourceId + "/uploadImage",
      method: "POST",
      data: {
        image: images,
      },
      formData: true,
    });
  };

  const deleteImages = (entityId: number, resourceId: number, imageIds: number[]) => {
    console.log("Deleting..", imageIds);
    return FetchService.callApi({
      endpoint: "/organization/" + entityId + "/resources/" + resourceId + "/deleteImages",
      method: "DELETE",
      data: {
        images: imageIds,
      },
    });
  };

  return {
    get: get,
    getByEntity: getByEntity,
    post: post,
    put: put,
    remove: remove,
    getAvailableSeats: getAvailableSeats,
    getSchedule: getSchedule,
    putSchedule: putSchedule,
    postSchedule: postSchedule,
    getImages: getImages,
    postImages: postImages,
    deleteImages: deleteImages,
  };
})();
