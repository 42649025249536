import { observer } from "mobx-react";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Form,
  MediaUploadModal,
  ResourceForm,
  Schedule,
  Spinner,
} from "../../components";
import { addBorders, addShadow, colors, config } from "../../config";
import { ResourceImage } from "../../interfaces";
import { globalProvider, userProvider } from "../../providers";
import { ResourceService, UtilsService } from "../../services";

export interface Props extends RouteComponentProps {
  resource: any;
}

interface State {
  updateError: boolean;
  resource: any;
  showEditImagesModal: boolean;
  images: ResourceImage[];
  loadingResource: boolean;
}

class EditResourceScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      updateError: false,
      resource: props.resource,
      showEditImagesModal: false,
      images: [],
      loadingResource: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    if (!this.state.resource) {
      this.setState({
        loadingResource: true,
      });
      let urlParams = this.props.location.pathname.split("/");
      let resourceId: any = urlParams[2];
      if (!resourceId) {
        resourceId = urlParams.pop();
      }
      await ResourceService.get(resourceId)
        .then((resource) => {
          if (resource.Entidades_id == userProvider.getEntityId) {
            this.setState({
              resource: resource,
            });
          } else {
            globalProvider.setParam({
              for: "ResourceScreen",
              value: resource,
            });
            this.props.history.push("/resource/" + resource.id);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => this.setState({ loadingResource: false }));
    }
    this.fetchImages();
  };

  fetchImages = () => {
    ResourceService.getImages(
      this.state.resource.Entidades_id,
      this.state.resource.id
    )
      .then((data = []) => {
        let imgs: ResourceImage[] = data.map((img: ResourceImage) => {
          return {
            id: img.id,
            image_path: config.getAPI_URL + "/uploads/" + img.image_path,
            predeterminada: img.predeterminada,
            Recursos_id: img.Recursos_id,
          };
        });
        this.setState({
          images: imgs,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  updateResource = (r: any) => {
    console.log(r);
    ResourceService.put(
      this.state.resource.Entidades_id,
      this.state.resource.id,
      {
        nombre: r.Nombre,
        precio: r.Precio,
        precio_promocional: r["Precio Promocional"]
          ? r["Precio Promocional"]
          : null,
        personas_habilitadas: r["Personas Habilitadas"],
        cantidad_total: r.Cantidad,
        descripcion: r.Descripcion,
        habilitado: r.Habilitado,
        max_turnos_reserva: r["Maximo # de turnos por " + config.getPlaceholders.reservation.singular.label],
        tiempo_turno: r["Tiempo p/ turno"],
      }
    )
      .then((data) => {
        console.log(data);
        globalProvider.showAlert({
          alertType: "popup",
          type: "info",
          message:
            config.getPlaceholders.resource.singular.extendedLabel +
            " " +
            config.getPlaceholders.resource.singular.updated +
            " correctamente!",
        });
      })
      .catch((error) => {
        console.error(error);
        globalProvider.showAlert({
          alertType: "popup",
          type: "error",
          message:
            "No pudimos actualizar " +
            config.getPlaceholders.resource.singular.the +
            " " +
            config.getPlaceholders.resource.singularLabel +
            "...",
        });
      });
  };

  updateResourceSchedule = (schedule: any) => {
    ResourceService.putSchedule(this.state.resource.id, schedule.id, schedule)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  deleteImages = (ids: number[]) => {
    return new Promise((resolve, reject) => {
      ResourceService.deleteImages(
        this.state.resource.Entidades_id,
        this.state.resource.id,
        ids
      )
        .then((data) => {
          resolve(data);
          this.fetchImages();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  uploadImages = (images: any[]) => {
    return new Promise((resolve, reject) => {
      ResourceService.postImages(
        this.state.resource.Entidades_id,
        this.state.resource.id,
        images
      )
        .then((data) => {
          resolve(data);
          this.fetchImages();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: 10,
        }}
      >
        {this.state.loadingResource ? (
          <Spinner />
        ) : this.state.resource ? (
          <div
            style={{
              minWidth: "50%",
              justifyContent: "center",
              //padding:0
              alignItems: "center",
              //width: '50%'
            }}
          >
            <MediaUploadModal
              title={"Imagenes de " + this.state.resource.nombre}
              removeUploadedImages={this.deleteImages}
              uploadNewImages={this.uploadImages}
              uploadedImages={this.state.images}
              show={this.state.showEditImagesModal}
              close={() => {
                this.setState({
                  showEditImagesModal: false,
                });
              }}
            />
            <h1>Actualizar {this.state.resource.nombre}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                ...addShadow,
                borderRadius: 10,
                height: "fit-content",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: 15,
                }}
              >
                <h3>Información General</h3>
                <ButtonGroup vertical>
                  <Button
                    style={{
                      //borderRadius: 10,
                      height: "fit-content",
                    }}
                    onClick={() => {
                      this.props.history.push(
                        "/resource/" + this.state.resource.id + "/schedule/edit"
                      );
                    }}
                  >
                    Actualizar Horario
                  </Button>
                  <Button
                    style={{
                      //borderRadius: 10,
                      height: "fit-content",
                    }}
                    onClick={() => {
                      this.setState({
                        showEditImagesModal: true,
                      });
                    }}
                  >
                    Actualizar Imagenes
                  </Button>
                </ButtonGroup>
              </div>

              <ResourceForm
                submit={this.updateResource}
                error={this.state.updateError}
                resource={this.state.resource}
              />
            </div>
          </div>
        ) : (
          <h1>Error...</h1>
        )}
      </div>
    );
  }
}

export default withRouter(observer(EditResourceScreen));
