import React from "react";
import {
  HiHome,
  HiShoppingCart,
  HiOutlineShoppingCart,
  HiLocationMarker,
} from "react-icons/hi";
import {
  BsFileEarmark,
  BsFillPersonFill,
  BsFilter,
  BsFiles,
  BsTrash
} from "react-icons/bs";
import {
  AiFillApple,
  AiFillTool,
  AiOutlineGoogle,
  AiOutlineMail,
  AiOutlineUnorderedList,
  AiOutlineUser,
  AiOutlineShop,
  AiOutlineSearch,
  AiOutlineShareAlt,
  AiOutlineHome,
} from "react-icons/ai";
import { MdClear, MdEdit, MdTune } from "react-icons/md";
import {
  IoMdHeart,
  IoMdHeartEmpty,
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowDropright,
} from "react-icons/io";
import { ImSad } from "react-icons/im";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GoVerified } from "react-icons/go";
import { RiPhoneLine, RiMapPinAddFill } from "react-icons/ri";
import { BiFingerprint, BiScan, BiPurchaseTagAlt } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";

export interface Props {
  name: string;
  color?: string;
  size?: number;
  type?: string;
}
export const Icon: React.FunctionComponent<Props> = (props: any) => {
  switch (props.name) {
    case "home":
      return <HiHome color={props.color} size={props.size} />;
      break;
    case "home-outline":
      return <AiOutlineHome color={props.color} size={props.size} />;
    case "person":
      return <BsFillPersonFill color={props.color} size={props.size} />;
      break;
    case "list":
      return <AiOutlineUnorderedList color={props.color} size={props.size} />;
      break;
    case "shopping-cart":
      return <HiShoppingCart color={props.color} size={props.size} />;
      break;
    case "shopping-cart-outline":
      return <HiOutlineShoppingCart color={props.color} size={props.size} />;
      break;
    case "clear":
      return <MdClear color={props.color} size={props.size} />;
      break;
    case "favorite":
      return <IoMdHeart color={props.color} size={props.size} />;
      break;
    case "favorite-border":
      return <IoMdHeartEmpty color={props.color} size={props.size} />;
      break;
    case "keyboard-arrow-left":
      return <IoIosArrowBack color={props.color} size={props.size} />;
    case "keyboard-arrow-down":
      return <IoIosArrowDown color={props.color} size={props.size} />;
      break;
    case "keyboard-arrow-right":
      return <IoIosArrowDropright color={props.color} size={props.size} />;
      break;
    case "sentiment-very-dissatisfied":
      return <ImSad color={props.color} size={props.size} />;
      break;
    case "info":
      return <AiOutlineInfoCircle color={props.color} size={props.size} />;
      break;
    case "verified":
      return <GoVerified color={props.color} size={props.size} />;
    case "person-outline":
      return <AiOutlineUser color={props.color} size={props.size} />;
      break;
    case "email":
      return <AiOutlineMail color={props.color} size={props.size} />;
      break;
    case "call":
      return <RiPhoneLine color={props.color} size={props.size} />;
      break;
    case "fingerprint":
      return <BiFingerprint color={props.color} size={props.size} />;
      break;
    case "edit":
      return <MdEdit color={props.color} size={props.size} />;
      break;
    case "google":
      return <AiOutlineGoogle color={props.color} size={props.size} />;
      break;
    case "apple1":
      return <AiFillApple color={props.color} size={props.size} />;
      break;
    case "build":
      return <AiFillTool color={props.color} size={props.size} />;
    case "storefront":
      return <AiOutlineShop color={props.color} size={props.size} />;
    case "center-focus-strong":
      return <BiScan color={props.color} size={props.size} />;
    case "search":
      return <AiOutlineSearch color={props.color} size={props.size} />;
    case "add-location-alt":
      return <RiMapPinAddFill color={props.color} size={props.size} />;
    case "place":
      return <HiLocationMarker color={props.color} size={props.size} />;
    case "share":
      return <AiOutlineShareAlt color={props.color} size={props.size} />;
    case "filter-list":
      return <BsFilter color={props.color} size={props.size} />;
    case "tune":
      return <MdTune color={props.color} size={props.size} />;
    case "purchase":
      return <BiPurchaseTagAlt color={props.color} size={props.size} />;
    case "logout":
      return <FiLogOut color={props.color} size={props.size} />;
    case "files":
      return <BsFiles color={props.color} size={props.size} />;
    case "trash":
      return <BsTrash color={props.color} size={props.size} />;
    default:
      return <>?</>;
  }
};
