import React from "react";
import GoogleMapReact from "google-map-react";

export interface Props {
  center: GoogleMapReact.Coords;
  zoom: number;
  height?: any;
  width?: any;
  style?:any;
}

interface MProps {
  lat: number;
  lng: number;
}
const MapMarker: React.FunctionComponent<MProps> = (props: MProps) => {
  return (
    <div>
      <p>marker!</p>
    </div>
  );
};

export const Map: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        height: props.height ? props.height : "100%",
        width: props.width ? props.width : "100%",
        borderRadius: 20,
        overflow: 'hidden',
        alignSelf: 'center',
        display:'flex',
        ...props.style
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
      >
        {<MapMarker lat={-31.418227} lng={-64.161342} />}
      </GoogleMapReact>
    </div>
  );
};
